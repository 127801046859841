import { Map } from 'immutable'

import { version } from '../../../package.json'
import {
  VIEW_SURVEY,
  VIEW_REPORT,
  TOGGLE_VIEW,
  TOGGLE_SIDEBAR,
  FINISH_WELCOME,
  OFFLINE,
  ONLINE,
  OFFLINE_ENABLE,
  OFFLINE_DISABLE,
  SERVER_CHECKIN,
  SERVER_CHECKIN_FAILED,
  LOGOUT,
  DO_MIGRATION,
  UPDATE_SEARCH_QUERY,
} from '../actions'

const initialState = Map({
  passedWelcomeScreen: false,
  sidebarVisible: true,
  mainView: 'survey',
  online: true,
  lastCheckIn: null,
  offlineMode: false,
  userLevel: 'standard',
  licenceKey: null,
  latestVersion: version,
  entriesSearch: undefined,
})

export const reduce = (s, a) => {
  s = s || initialState

  switch (a.type) {
    case VIEW_SURVEY:
      return s.set('mainView', 'survey')
    case VIEW_REPORT:
      return s.set('mainView', 'report')
    case TOGGLE_SIDEBAR:
      return s.set('sidebarVisible', !s.get('sidebarVisible'))
    case TOGGLE_VIEW:
      if (s.get('mainView') === 'survey') {
        return s.set('mainView', 'report')
      } else {
        return s.set('mainView', 'survey')
      }
    case FINISH_WELCOME:
      return s.set('passedWelcomeScreen', true).set('licenceKey', a.key)
    case OFFLINE:
      return s.set('online', false)
    case ONLINE:
      return s.set('online', true)
    case OFFLINE_ENABLE:
      return s.set('offlineMode', true)
    case OFFLINE_DISABLE:
      return s.set('offlineMode', false)
    case SERVER_CHECKIN:
      return s
        .set('lastCheckIn', a.timestamp)
        .set('userLevel', a.userLevel)
        .set('latestVersion', a.latestVersion)
    case SERVER_CHECKIN_FAILED:
      return s
    case LOGOUT:
      return s.set('offlineMode', false).set('passedWelcomeScreen', false)
    case DO_MIGRATION:
      console.log('doing migration', a)
      const { key, mode, passedWelcome } = a
      return s
        .set('licenceKey', key || initialState.get('licenceKey'))
        .set('offlineMode', mode || initialState.get('offlineMode'))
        .set(
          'passedWelcomeScreen',
          passedWelcome || initialState.get('passedWelcomeScreen'),
        )
    case UPDATE_SEARCH_QUERY:
      return s.set('entriesSearch', a.query)
    default:
      return s
  }
}
