import { Map } from 'immutable'

import { UPDATE_CUSTOM_FOOTER } from '../actions'

const initialState = Map({
  footer: '',
  logo: null,
})

export function reduce(s = initialState, a) {
  switch (a.type) {
    case UPDATE_CUSTOM_FOOTER:
      return s.set('footer', a.value)
    default:
      return s
  }
}
