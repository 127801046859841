import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { currentView } from '../model/selectors'

import SurveyLayout from './SurveyLayout'
import ReportLayout from './ReportLayout'

const Container = styled.div`
  width: 8.5in;
  flex-direction: column;
  padding: 20px;
  flex: none;
  margin: auto;
  box-sizing: content-box;

  .not_en & {
    width: 210mm;
  }
`

const mainContent = ({ view, className }) => {
  if (view === 'survey') {
    return (
      <Container className={className + ' mainViewContainer'}>
        <SurveyLayout />
      </Container>
    )
  } else {
    return (
      <Container className={className + ' mainViewContainer'}>
        <ReportLayout />
      </Container>
    )
  }
}

const mapState = (state) => {
  return {
    view: currentView(state),
  }
}

export default connect(mapState)(mainContent)
