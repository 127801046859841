import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import cs from 'classnames'
import Fuse from 'fuse.js'
import { createSelector } from 'reselect'

import { getSummary } from '../lib/healthAge'
import { entries, currentEntryId, search, entryById } from '../model/selectors'
import { selectEntry, updateSearchQuery } from '../model/actions'
import { t } from '../lib/i18n'
import LetterAvatar from './LetterAvatar'

const Container = styled.ul`
  overflow: auto;
  flex: 1;
`

const maybe = (value, getLabel) => {
  if (!value) {
    return null
  }

  return getLabel(value)
}

const BoundEntry = connect(
  // mapState
  (state, { id }) => {
    return {
      entry: entryById(id, state),
      active: id === currentEntryId(state),
    }
  },
  // eventMap
  { select: selectEntry },
)(({ id, entry, select, active }) => {
  const results = getSummary(entry, true)

  const description = [
    maybe(results.get('gender'), (g) => {
      if (g === 'male') {
        return t(`Male`)
      } else {
        return t(`Female`)
      }
    }),
    maybe(results.get('age'), (a) => `${t(`Age`)} ${a}`),
    maybe(results.get('healthAge'), (h) => `${t(`Health Age`)} ${h}`),
  ]
    .filter((e) => !!e)
    .join(', ')

  return (
    <li
      className={cs('list-group-item', {
        active: active,
      })}
      onClick={() => select(id)}
      key={id}
      style={{ cursor: 'pointer' }}
    >
      <LetterAvatar
        className="img-circle media-object pull-left"
        letters={maybe(results.get('name'), (name) =>
          name
            .split(' ')
            .map((name) => name.charAt(0))
            .slice(0, 3),
        )}
        score={results.get('score')}
      />
      <div className="media-body">
        <strong>{results.get('name') || t(`New Entry`)}</strong>
        <p>{description}</p>
      </div>
    </li>
  )
})

const Entries = ({ entries, current, select, search, updateSearch }) => {
  const listItems = entries.map((entryId) => {
    return <BoundEntry id={entryId} key={entryId} />
  })

  return (
    <Container className="list-group">
      <li className="list-group-header">
        <input
          className="form-control"
          type="text"
          placeholder={t(`Search for someone`)}
          value={search || ''}
          onChange={(e) => updateSearch(e.target.value)}
        />
      </li>
      {listItems}
    </Container>
  )
}

const fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  id: 'id',
  keys: ['name', 'email', 'address', 'phone', 'zip', 'age', 'gender'],
}

const allEntryIds = createSelector(
  entries,
  (entries) => {
    return entries.keySeq().toArray()
  },
)

const fuseInstance = createSelector(
  entries,
  (entries) => {
    const fuseData = []
    entries.forEach((entry, id) =>
      fuseData.push(Object.assign(entry.get('meta').toObject(), { id })),
    )
    return new Fuse(fuseData, fuseOptions)
  },
)

const searchResult = createSelector(
  [fuseInstance, search],
  (fuseInstance, query) => {
    return fuseInstance.search(query)
  },
)

const visibleEntries = (state) => {
  // Don't bother to init Fuse if there is no search query
  if (!search(state)) {
    return allEntryIds(state)
  }

  return searchResult(state)
}

const mapState = (state) => {
  const visibleEntryIds = visibleEntries(state)

  return {
    entries: visibleEntryIds,
    search: search(state),
  }
}

const eventMap = {
  updateSearch: updateSearchQuery,
}

export default connect(
  mapState,
  eventMap,
)(Entries)
