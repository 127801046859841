import React from "react";
import styled from "styled-components";
import autoBind from "auto-bind";

import { connect } from "react-redux";
import { updateSurvey, toggleInterest } from "../model/actions";

import Header from "./Header";
import Question from "./Question";
import Footer from "./Footer";

import Shortcut from "../lib/shortcut";
import { iqdb } from "../lib/qdb";

const QUESTION_COUNT = 12;

const Container = styled.div`
  ${"" /* max-width: 8.5in; */} display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  ${"" /* max-width: 100vw; */};
`;

const QuestionsC = styled.div`
  column-count: 2;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  padding: 7px 25px;
  position: relative;

  @media (max-width: 500px) {
    column-count: 1;
  }
`;

const Qc = styled.div`
  page-break-inside: avoid;
`;

// create a keystroke map for Footer
const ksPool = "qwerasdfjkl".split("");

const keys = [
  "wm",
  "cooking",
  "heart",
  "diabetes",
  "exersise",
  "stress",
  "depression",
  "mind",
  "addictions",
  "remedies",
  "spiritual"
];

const ksMap = {};
const ksReverse = {};
keys.forEach((k, i) => {
  const ks = ksPool[i];
  ksMap[k] = ks;
  ksReverse[ks] = k;
});

class SurveyView extends React.Component {
  constructor() {
    super();
    autoBind(this);

    this.state = {
      focusedQuestion: null,
      focusedFooter: false
    };
  }
  onFocus(focusedQuestion) {
    this.setState({ focusedQuestion });
  }
  onBlur(id) {
    // this function is more complicated because it has to handle
    // focus being fired before the blur.
    if (this.state.focusedQuestion === id) {
      this.setState({
        focusedQuestion: null
      });
    }
  }
  focusFooter() {
    this.setState({ focusedFooter: true });
  }
  blurFooter() {
    this.setState({ focusedFooter: false });
  }
  numkey(e, key) {
    // only fire this event if a question currently has focus
    if (Number.isInteger(this.state.focusedQuestion)) {
      // don't allow answering a question to an impossible answer
      const activeQuestion = iqdb.get(this.state.focusedQuestion);
      const answerCount = activeQuestion.get("options").size;
      if (key > answerCount) {
        key = answerCount;
      }
      // dispatch the update
      this.props.updateSurvey(this.state.focusedQuestion, key - 1);
    }
  }
  charkey(e, key) {
    if (this.state.focusedFooter) {
      const slug = ksReverse[key];
      this.props.toggleInterest(slug);
    }
  }
  render() {
    // create an array of question components
    let questions = [];
    for (let i = 0; i < QUESTION_COUNT; i++) {
      questions.push(
        // this is a dummy element to catch the focus/blur events
        <Qc
          key={i}
          onFocus={() => this.onFocus(i)}
          onBlur={() => this.onBlur(i)}
        >
          <Question id={i} />
        </Qc>
      );
    }

    return (
      <Container>
        {/* keyboard shortcut handlers */}
        <Shortcut code={["1", "2", "3", "4"]} handler={this.numkey} />
        <Shortcut code={ksPool} handler={this.charkey} />

        {/* the red form header */}
        <Header />

        {/* container for all the survey questions */}
        <QuestionsC>{questions}</QuestionsC>

        {/* dummy div to catch focus events */}
        <div
          onFocus={() => this.focusFooter()}
          onBlur={() => this.blurFooter()}
        >
          {/* Footer with interest questions */}
          <Footer ksMap={ksMap} />
        </div>
      </Container>
    );
  }
}

const mapState = () => {
  return {};
};
const eventMap = {
  updateSurvey: updateSurvey,
  toggleInterest: toggleInterest
};

export default connect(
  mapState,
  eventMap
)(SurveyView);
