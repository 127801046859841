// @flow

import React from 'react'
import { keyframes } from 'styled-components'
import styled from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinnerEl = styled.div`
  animation: ${spin} 1.5s linear infinite;
`

const Svg = styled.svg`
  height: 100%;
`

const Spinner = ({ className }) => {
  return (
    <SpinnerEl className={className}>
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 100 100"
      >
        <g transform="translate(0,-952.36217)">
          <path
            d="m 48.51666,954.86218 a 4.0805736,4.0808569 0 0 1 0.409902,0.10227 l 1.742085,0 a 4.0805736,4.0808569 0 0 1 4.09902,3.99683 l 0,24.59583 a 4.0805736,4.0808569 0 0 1 -4.09902,4.09931 l -1.742085,0 a 4.0805736,4.0808569 0 0 1 -4.09902,-4.09931 l 0,-24.59583 a 4.0805736,4.0808569 0 0 1 3.279216,-3.99683 4.0805736,4.0808569 0 0 1 0.409902,-0.10227 z"
            fill="#ffffff"
            fillOpacity="1"
          />
          <path
            d="m 19.516088,967.05761 a 4.0805736,4.0808569 0 0 1 3.279217,1.2298 l 17.420839,17.31957 a 4.0805736,4.0808569 0 0 1 -0.10231,5.73902 l -1.229706,1.22978 a 4.0805736,4.0808569 0 0 1 -0.614853,0.61491 4.0805736,4.0808569 0 0 1 -5.123776,-0.51242 L 15.724658,975.25623 a 4.0805736,4.0808569 0 0 1 0,-5.73903 l 1.229706,-1.22979 a 4.0805736,4.0808569 0 0 1 2.561888,-1.2298 z"
            fill="#ffffff"
            fillOpacity="0.875"
          />
          <path
            d="m 5.7843692,997.69996 a 4.0805736,4.0808569 0 0 1 0.8198041,0 l 24.4916487,0 a 4.0805736,4.0808569 0 0 1 4.099021,3.99664 l 0,1.7423 a 4.0805736,4.0808569 0 0 1 -4.099021,4.0994 l -24.4916487,0 a 4.0805736,4.0808569 0 0 1 -4.0990215,-3.6894 4.0805736,4.0808569 0 0 1 0,-0.41 l 0,-1.7423 a 4.0805736,4.0808569 0 0 1 3.2792174,-3.99664 z"
            fill="#ffffff"
            fillOpacity="0.750"
          />
          <path
            d="m 35.809696,1011.1246 a 4.0805736,4.0808569 0 0 1 3.381692,1.2299 l 1.229707,1.2298 a 4.0805736,4.0808569 0 0 1 -0.10231,5.6367 l -17.318363,17.3194 a 4.0805736,4.0808569 0 0 1 -5.431203,0.4099 4.0805736,4.0808569 0 0 1 -0.307262,-0.3083 l -1.229706,-1.2297 a 4.0805736,4.0808569 0 0 1 0,-5.7391 l 17.318363,-17.3194 a 4.0805736,4.0808569 0 0 1 2.459412,-1.2298 z"
            fill="#ffffff"
            fillOpacity="0.625"
          />
          <path
            d="m 48.721611,1017.171 a 4.0805736,4.0808569 0 0 1 0.409902,0.1017 l 1.742085,0 a 4.0805736,4.0808569 0 0 1 3.996545,3.997 l 0,24.5958 a 4.0805736,4.0808569 0 0 1 -3.996545,3.9966 l -1.742085,0 a 4.0805736,4.0808569 0 0 1 -4.09902,-3.9966 l 0,-24.5958 a 4.0805736,4.0808569 0 0 1 3.279216,-3.997 4.0805736,4.0808569 0 0 1 0.409902,-0.1017 z"
            fill="#ffffff"
            fillOpacity="0.500"
          />
          <path
            d="m 68.089485,997.80227 a 4.0805736,4.0808569 0 0 1 0.819804,0 l 24.491649,0 a 4.0805736,4.0808569 0 0 1 4.09902,4.09933 l 0,1.742 a 4.0805736,4.0808569 0 0 1 -4.09902,4.0993 l -24.491649,0 a 4.0805736,4.0808569 0 0 1 -4.099021,-3.6894 4.0805736,4.0808569 0 0 1 0,-0.4099 l 0,-1.742 a 4.0805736,4.0808569 0 0 1 3.279217,-4.09933 z"
            fill="#ffffff"
            fillOpacity="0.375"
          />
          <path
            d="m 63.068185,1011.227 a 4.0805736,4.0808569 0 0 1 3.689118,1.1275 l 17.318362,17.3194 a 4.0805736,4.0808569 0 0 1 0,5.8416 l -1.229706,1.2299 a 4.0805736,4.0808569 0 0 1 -0.307263,0.3083 4.0805736,4.0808569 0 0 1 -5.431202,-0.41 l -17.318363,-17.3198 a 4.0805736,4.0808569 0 0 1 0,-5.739 l 1.229707,-1.2298 a 4.0805736,4.0808569 0 0 1 2.04951,-1.1271 z"
            fill="#ffffff"
            fillOpacity="0.250"
          />
          <path
            d="m 80.284072,967.15994 a 4.0805736,4.0808569 0 0 1 2.971789,1.22979 l 1.229706,1.22978 a 4.0805736,4.0808569 0 0 1 -0.10231,5.73904 l -17.318363,17.31956 a 4.0805736,4.0808569 0 0 1 -5.431202,0.30728 4.0805736,4.0808569 0 0 1 -0.307263,-0.20496 l -1.229706,-1.22979 a 4.0805736,4.0808569 0 0 1 0,-5.84152 l 17.318363,-17.31956 a 4.0805736,4.0808569 0 0 1 2.869314,-1.22979 z"
            fill="#ffffff"
            fillOpacity="0.125"
          />
        </g>
      </Svg>
    </SpinnerEl>
  )
}

export default Spinner
