import { t } from './i18n'
import { OrderedMap } from 'immutable'
import { iqdb } from './qdb'
import utf8 from 'utf-8'
import base64 from 'base64-arraybuffer'
import yoinkit from 'yoinkit'
import stringify from 'csv-stringify/lib/sync'

const getMetaFields = () => {
  return OrderedMap({
    name: t(`Name`),
    email: t(`Email`),
    address: t(`Address`),
    phone: t(`Phone`),
    zip: t(`Zip`),
    age: t(`Age`),
    gender: t(`Gender`),
    weight: t(`Weight`),
    height: t(`Height`),
    wm: t(`Weight Management`),
    cooking: t(`Healthy Cooking`),
    heart: t(`Heart Disease Prevention`),
    diabetes: t(`Reversing Diabetes`),
    exersise: t(`Fitness and Exercise`),
    stress: t(`Stress Management`),
    depression: t(`Depression Recovery`),
    mind: t(`Mental Performance`),
    addictions: t(`Stop Smoking`),
    remedies: t(`Natural Remedies`),
    spiritual: t(`Spiritual Health`),
    other: t(`Other`),
  })
}

const metaLine = (meta) => {
  // lookup each of the possible meta field values
  return getMetaFields()
    .keySeq()
    .map((key) => {
      const value = meta.get(key)
      // if it's a string, then just print it
      if (typeof value === 'string') {
        return value
      } else {
        return value ? 'yes' : ''
      }
    })
}

const surveyLine = (selections) => {
  // get the caption for each selected item
  return selections.map((sid, qid) => {
    return iqdb.getIn([qid, 'options', sid, 'caption']) || ''
  })
}

export const toCsv = (entries) => {
  // build the header
  const metaHeader = getMetaFields().toList()
  const surveyHeader = iqdb.map((q) => q.get('slug'))
  const headerLine = metaHeader.concat(surveyHeader).toJS()

  // make a line for each entry
  const lines = entries
    .toList()
    .map((entry) => {
      // concat the meta results to the survey results to make a complete line
      return metaLine(entry.get('meta')).concat(surveyLine(entry.get('survey')))
    })
    .toJS()

  return stringify(lines, { columns: headerLine, header: true })
}

export const exportCsv = (entries) => {
  const output = toCsv(entries)
  const data = base64.encode(utf8.setBytesFromString(output))
  console.log('exporting', yoinkit.canYoinkit())

  yoinkit({
    data: data,
    encoding: 'base64',
    contentType: 'text/plain',
    filename: 'entries.csv',
  })
}

export const exportHer = (fileContents) => {
  const data = base64.encode(utf8.setBytesFromString(fileContents))
  yoinkit({
    data: data,
    encoding: 'base64',
    contentType: 'text/plain',
    filename: 'expo.her',
  })
}
