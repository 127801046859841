import React from "react";
import Checkbox from "./elements/Checkbox.js";
import { connect } from "react-redux";
import { survey } from "../model/selectors";
import { updateSurvey } from "../model/actions";
import { iqdb } from "../lib/qdb";
import styled from "styled-components";

const Option = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px;
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:hover {
    background-color: hsla(0, 0%, 97%, 1);
  }
`;

const Container = styled.div`
  background: white;
  padding: 10px;
  flex-grow: 0;
  overflow: hidden;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const Title = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  font-family: roboto slab;
  cursor: pointer;
`;

const Question = ({ id, currentSelected, update }) => {
  const q = iqdb.get(id); // question from the database
  const u = selection => update(id, selection); // update function

  // map over all the choices
  const choices = q.get("options").map((choice, i) => {
    const select = u.bind(null, i); // function to select this choice
    const isSelected = i === currentSelected; // bool indicating if this option is selected

    return (
      <Option onClick={select} key={choice}>
        <Checkbox checked={isSelected} keystroke={i + 1} />
        {choice.get("caption")}
      </Option>
    );
  });

  // return the question
  return (
    <Container tabIndex="0">
      <Title>{q.get("title")}</Title>
      {choices}
    </Container>
  );
};

const mapState = (initialState, initialProps) => state => {
  return {
    currentSelected: survey(state).get(initialProps.id)
  };
};

const eventMap = {
  update: updateSurvey
};

export default connect(
  mapState,
  eventMap
)(Question);
