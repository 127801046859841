import React from 'react'
import styled from 'styled-components'
import { t } from '../lib/i18n.js'

const Container = styled.div`
  font-size: 0.7em;
  font-weight: bold;
  margin-left: 8px;
  user-select: none;
  display: inline-flex;
  border: 1px solid #828282;
  border-radius: 5px;
  padding: 5px;
  padding-left: 0px;
  padding-right: 8px;
  cursor: pointer;
`

const Option = styled.span`
  margin-left: 8px;
  display: flex;
  align-items: center;

  & > span {
    margin-left: 4px;
  }
`

const Radio = ({ on }) => {
  const style = { cursor: 'pointer' }
  return <input type="radio" readOnly style={style} checked={on} />
}

const Switch = ({ on, change, tip }) => {
  const balloon = tip
    ? {
        'data-balloon': tip,
        'data-balloon-pos': 'up',
      }
    : {}

  return (
    <Container onClick={() => change(!on)} {...balloon}>
      <Option>
        <Radio on={on} /> <span>{t(`On`)}</span>
      </Option>
      <Option>
        <Radio on={!on} /> <span>{t(`Off`)}</span>
      </Option>
    </Container>
  )
}

export default Switch
