import React from 'react'
import styled from 'styled-components'

// right now this is used for the address fields in the footer

const TextInput = styled.div`
  display: flex;
  width: 50%;
  margin: 5px 0px;
  padding: 0 5px;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: flex-start;
`

const Label = styled.label`
  margin-right: 4px;
  font-weight: 500;
`

const Input = styled.input`
  border: none;
  border-bottom: 1px solid black;
  flex-grow: 1;
  outline: none;
  width: 10px;
  border-radius: 0;
`

const LabeledField = ({ caption, value, onChange, className }) => {
  const handleInput = (e) => onChange(e.target.value)

  return (
    <TextInput className={className}>
      <Label>{caption}</Label>
      <Input type="text" value={value || ''} onChange={handleInput} />
    </TextInput>
  )
}

export default LabeledField
