import styled from 'styled-components'
import React from 'react'

import { t } from '../lib/i18n'
import { config } from '../model/globalConfig'
import { alert } from '../lib/vex'

import pencil from '../assets/compose.svg'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  margin-bottom: 8px;
`

const Label = styled.label`
  position: relative;
  margin-right: 7px;
  cursor: pointer;
  background-image: url('${(p) => p.bg}');
  height: 100%;
  min-width: 55px;
  display: ${(p) => (p.hasLogo ? 'block' : 'none')};

  ${Container}:hover > & {
    display: block;
  }

  @media print {
    display: ${(p) => p.hasLogo || 'none'} !important;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: gray;
  font-weight: 100;
  background: rgba(0, 0, 0, 0.33);
  border-radius: 20%;
  color: white;

  ${Container}:hover & {
    display: flex;
  }
`

const Input = styled.input`
  display: none;
`

const Logo = styled.img`
  max-height: 100%;
  max-width: 100%;
`

const Title = styled.h1`
  color: #2e3092;
  margin: 0;
  font-size: 50px;
  font-weight: 400;
  font-family: noto serif;
  text-align: center;
  flex-shrink: 0;

  .not_en & {
    font-size: 40px;
  }

  .locale__ru & {
    font-size: 36px;
  }
`

const LogoContainer = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
`

const Icon = styled.span`
  font-size: 25px;
`

const handleFile = (e) => {
  const file = e.target.files[0]
  const reader = new window.FileReader()
  reader.addEventListener('load', () => {
    // we store the logo separate from the main state to avoid performance hits with large files.
    if (reader.result.length < 2000000) {
      config('customLogo', reader.result)
      window.location.reload()
    } else {
      alert(
        t(
          `Large logos will make the app sluggish.  Please resize the image to be less than 1 megabyte and try again.`,
        ),
      )
    }
  })
  reader.readAsDataURL(file)
}

const MaybeLogo = () => {
  const url = config('customLogo')
  if (url) {
    return <Logo src={url} />
  } else {
    return null
  }
}

const removeLogo = (e) => {
  e.preventDefault()
  config('customLogo', '')
  window.location.reload()
}

const SmartOverlay = ({ hasLogo }) => {
  let content = '+'
  if (hasLogo) {
    content = [
      <img src={pencil} alt="edit" key="img" />,
      <Icon key="remove" onClick={removeLogo}>
        &#10005;
      </Icon>,
    ]
  }
  return <Overlay>{content}</Overlay>
}

const Header = () => {
  const hasLogo = !!config('customLogo')
  return (
    <Container className="specialPoorlyCoupledClass">
      <Label hasLogo={hasLogo}>
        <LogoContainer>
          <MaybeLogo />
        </LogoContainer>
        <SmartOverlay hasLogo={hasLogo} />
        {/* gotta have this to enable the file API */}
        <Input type="file" onChange={handleFile} />
      </Label>
      <Title className="reportTxtHeader" hasLogo={hasLogo}>
        {t(`Discover Your Health Age`)}
      </Title>
    </Container>
  )
}

export default Header
