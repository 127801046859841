import React from 'react'
import styled from 'styled-components'

import grayLogo from '../assets/logochuck-gray.png'
import spinner from '../assets/loading.gif'

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`

const CenterContainer = styled.div`
margin: auto;
display: flex;
flex-direction: column;
font-size: 20px;
align-items: center;
`

const LoginBox = styled.div`
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.12);
  background: white;
  padding: 25px;
  width: ${(p) => p.width || '400px'};
  display: flex;
  flex-direction: column;
  position: relative;
`

const Footer = styled.a`
  bottom: 10px;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`

export const Container = ({ width, children, alert }) => {
  return (
    <PageContainer>
      <CenterContainer>{alert}
        <LoginBox width={width}>{children}</LoginBox></CenterContainer>
      <Footer href="https://www.healthexpobanners.com" target="_blank">
        <img alt="HER Logo" src={grayLogo} />
      </Footer>
    </PageContainer>
  )
}

const Spinner = styled.img`
  align-self: center;
  width: 102px;
`

const LoadingCaption = styled.h3`
  align-self: center;
  text-align: center;
  font-size: 35px;
  font-weight: 400;
  margin-top: 0.7em;
  margin-bottom: 0;
`

export const Loading = ({ msg }) => {
  return (
    <Container>
      <Spinner src={spinner} />
      <LoadingCaption>{msg}</LoadingCaption>
    </Container>
  )
}
