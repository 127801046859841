import React, { useState } from 'react'
import { keyframes } from 'styled-components'
import styled from 'styled-components'
import { getJson, postJson } from '../lib/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleNotch,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons'
import AsyncSelect from 'react-select/async'

const Container = styled.div`
  background: linear-gradient(to right, #6f0000, #200122);

  height: 80px;
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 0 25px;
  flex: none;
`

const SearchForm = styled.form``
const SearchLabel = styled.label`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  & > span {
    color: hsla(0, 0%, 100%, 0.87);
  }
`

// const SearchBox = styled.input`
//   background: rgba(255, 255, 255, 0.29);
//   border-radius: 7px;
//   border: ${(p) => (p.error ? '2px solid red' : 'none')};
//   font-size: 20px;
//   padding: 5px 13px;
//   color: white;
//   margin-left: 1ch;
// `
const SearchBox = styled(AsyncSelect)`
  margin-left: 1ch;
  width: 30ch;
`

// const TextFieldIcon = styled(FontAwesomeIcon)`
//   position: absolute;
//   right: 5px;
//   font-size: 20px;
// `

const NewIcon = styled(FontAwesomeIcon)`
  margin-left: 1ch;
`

// const ErrIcon = styled(FontAwesomeIcon)`
//   position: absolute;
//   right: 5px;
//   font-size: 20px;
//   // color: hsla(354, 66%, 55%, 1);
// `

const CreateButton = styled.button`
  background: rgba(255, 255, 255, 0.29);
  margin-left: auto;
  border-radius: 7px;
  border: none;
  font-size: 20px;
  padding: 5px 13px;
  color: white;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  ${(p) =>
    p.error && `animation: ${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both;`};
`

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const KeyList = ({ currentKey, setCurrentKey }) => {
  let [loadingNew, setLoadingNew] = useState(false)
  let [errNew, setErrNew] = useState(false)

  const createKey = async () => {
    setErrNew(false)
    setLoadingNew(true)
    try {
      const keyInfo = await getJson('/admin/createKey/')
      setCurrentKey(keyInfo.key)
    } catch (e) {
      console.log(e)
      setErrNew(true)
    } finally {
      setLoadingNew(false)
    }
  }
  const handleSearch = async (query) => {
    let exactMatch = null
    try {
      exactMatch = await getJson('/admin/keys/' + query)
    } catch (_e) {
      // no exact match
    }
    let matchingKeys = await postJson('/admin/search_keys/', { query })

    if (exactMatch) {
      console.log(exactMatch)
      matchingKeys = matchingKeys.filter((key) => key !== exactMatch.key)
      matchingKeys.unshift(exactMatch.key)
    }

    return matchingKeys.map((key) => {
      return { label: key, value: key }
    })
  }

  return (
    <Container>
      <SearchForm>
        <SearchLabel>
          <span>Look up a license key:</span>
          <SearchBox
            defaultOptions
            loadOptions={handleSearch}
            value={{ value: currentKey, label: currentKey }}
            onChange={(selected) => {
              setCurrentKey(selected.value)
            }}
          />
        </SearchLabel>
      </SearchForm>
      <CreateButton onClick={createKey} error={errNew}>
        ᐩ Create New Key
        {loadingNew && <NewIcon icon={faCircleNotch} spin />}
        {errNew && <NewIcon icon={faExclamationTriangle} />}
      </CreateButton>
    </Container>
  )
}

export default KeyList
