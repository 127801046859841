import { t } from './i18n'
import { fromJS } from 'immutable'
import React from 'react'

const bmiSubtitle = ({ bmi }) => {
  return (
    <ul>
      <li>{t(`BMI between 18.5 - 24.9; your BMI is: ##BMI##`, bmi || '?')}</li>
      <li>{t(`Waist circumference less than 40 in or 102 cm (men)`)}</li>
      <li>{t(`Waist circumference less than 35 in or 89 cm (women)`)}</li>
    </ul>
  )
}

const textBmiSubtitle = ({ bmi }) => {
  return (
    '* ' +
    t(`BMI between 18.5 - 24.9; your BMI is: ##BMI##`, bmi || '?') +
    '\n' +
    '* ' +
    t(`Waist circumference less than 40 in or 102 cm (men)`) +
    '\n' + // eslint-disable-line
    '* ' +
    t(`Waist circumference less than 35 in or 89 cm (women)`) +
    '\n'
  ) // eslint-disable-line
}

const getMutableQuestions = () => {
  const questions = [
    {
      title: t(`How often do you eat a good breakfast?`),
      slug: t(`Breakfast`),
      thresholdId: 2,
      options: [
        {
          caption: t(`Less than 2 days per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`2-4 days per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`5-6 days per week`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`Every day`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Eat a good breakfast daily`),
        subtitle: t(
          `Breakfast boosts your metabolism, helps you concentrate and perform better and school and work.  It also aids in maintaining a healthy weight.`,
        ),
      },
    },

    {
      title: t(`How often do you snack?`),
      slug: t(`Snack`),
      options: [
        {
          caption: t(`Several times a day`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`Once a day`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`A few times per week`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`Rarely or never`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Avoid Snacking`),
        subtitle: t(
          `For many people snacking adds an average of 580 calories per day.  Snacking impairs digestion, may disturb blood sugar control, and disrupt sleep.  It also contributes to weight gain 3500 calories = 1 pound).`,
        ),
      },
    },

    {
      title: t(
        `How many servings of fruits and vegetables do you eat per day?`,
      ),
      slug: t(`Fruits/Vegetables`),
      options: [
        {
          caption: t(`0-2 servings per day`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`3-4 servings per day`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`5 or more servings per day`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Enjoy more fruits and vegetables`),
        subtitle: t(
          `These foods are packed with phytochemicals, vitamins, nutrients, and fiber.  They are also lower in calories than other foods.`,
        ),
      },
    },

    {
      title: t(`How many servings of whole grains do you eat per day?`),
      slug: t(`Whole Grains`),
      options: [
        {
          caption: t(`None`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`1-2 servings per day`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`3+ servings per day`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Increase whole grain intake`),
        subtitle: t(
          `Whole grain contains many important nutrients (fiber, B vitamins, and minerals).`,
        ),
      },
    },

    {
      title: t(`How many servings of nuts and seeds do you eat per week?`),
      slug: t(`Nuts/Seeds`),
      options: [
        {
          caption: t(`0-2 per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`3-4 per week`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`5 or more per week`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Eat more nuts`),
        subtitle: t(
          `Nuts are an excellent source of protein and good fats. They also help control blood sugar levels.`,
        ),
      },
    },

    {
      title: t(`How often do you eat red meat?`),
      slug: t(`Red Meat`),
      options: [
        {
          caption: t(`3 or more times per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`1-2 times per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`Once or twice per month`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`Never`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Avoid red meat`),
        subtitle: t(
          `Eating red meat has been associated with type 2 diabetes, coronary heart disease, stroke and certain cancers.`,
        ),
      },
    },

    {
      title: t(
        `How often do you get 20-30 minutes of moderate to vigorous exercise`,
      ),
      slug: t(`Exercise`),
      options: [
        {
          caption: t(`Rarely`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`1-2 days per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`3-4 days per week`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`5+ days per week`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Exercise regularly`),
        subtitle: t(
          `Exercise boosts energy, improves mood, improves cholesterol levels, lowers blood pressure, promotes sound sleep, enhances immune function, helps control blood sugar, and more!`,
        ),
      },
    },

    {
      title: t(`How is your weight?`),
      slug: t(`Weight`),
      options: [
        {
          caption: t(`Severely overweight`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`Moderately overweight`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`Underweight`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`Healthy weight`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Achieve a healthy weight`),
        subtitle: bmiSubtitle,
        textSubtitle: textBmiSubtitle,
      },
    },

    {
      title: t(`How often do you get 7-8 hours of sleep?`),
      slug: t(`Sleep`),
      thresholdId: 2,
      options: [
        {
          caption: t(`2 or fewer days per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`3-4 days per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`5-6 days per week`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`Every day`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Get 7-8 hours of good sleep each night`),
        subtitle: t(
          `Sleep is important for our memory, learning, metabolism, and immune system.`,
        ),
      },
    },

    {
      title: t(`What is your history with tobacco?`),
      slug: t(`Tobacco`),
      thresholdId: 1,
      options: [
        {
          caption: t(`Currently use`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`Quit less than two years ago`),
          value: 0,
          reportValue: 0.5,
        },
        {
          caption: t(`Quit over 2 years ago`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`Never used`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Stop smoking`),
        subtitle: t(
          `Smoking harms every organ of the body, increasing the risk of various cancers, heart disease, COPD, asthma, and dental problems.`,
        ),
      },
    },

    {
      title: t(`How many servings of alcohol do you consume per week?`),
      slug: t(`Alcohol`),
      options: [
        {
          caption: t(`15+ (men) or 8+ (women)`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`1-7 servings per week`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`None`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Do not drink alcohol`),
        subtitle: t(
          `Alcohol use can lead to the following problems: neurological, cardiovascular, psychiatric, social, and increases the risk of cancer and liver disease.`,
        ),
      },
    },

    {
      title: t(`How would you rate your spirituality?`),
      slug: t(`Spirituality`),
      options: [
        {
          caption: t(`No interest`),
          value: 0,
          reportValue: 0,
        },
        {
          caption: t(`Moderately spiritual`),
          value: 0.5,
          reportValue: 0.5,
        },
        {
          caption: t(`Deeply spiritual`),
          value: 1,
          reportValue: 1,
        },
      ],
      rcmd: {
        title: t(`Increase spirituality`),
        subtitle: t(
          `Those who have faith in God may have improved mental health and immune function`,
        ),
      },
    },
  ]

  return [
    questions[0],
    questions[2],
    questions[4],
    questions[6],
    questions[8],
    questions[10],
    questions[1],
    questions[3],
    questions[5],
    questions[7],
    questions[9],
    questions[11],
  ]
}

const questions = getMutableQuestions()
const iqdb = fromJS(questions)

const getQuestions = () => {
  return iqdb
}

// get score value of a particular selection
const valueOf = (questionId, selectionId) => {
  // console.log(`valueOf #${questionId}.${selectionId}`)
  return iqdb.getIn([questionId, 'options', selectionId, 'value']) || 0
}

// check if a selection is the best possible on a question
const isSubPar = (questionId, selectionId) => {
  // it's considered to be subpar if they haven't selected anything yet.
  if (!selectionId) {
    return true
  }

  // see if we have an override in place
  const threshold = iqdb.getIn([questionId, 'thresholdId'])
  if (threshold) {
    return selectionId < threshold
  }

  // otherwise lookup the score and fire if it's less than one
  return valueOf(questionId, selectionId) < 1
}

const getReportValue = (questionId, selectionId) => {
  return iqdb.getIn([questionId, 'options', selectionId, 'reportValue'])
}

export {
  iqdb,
  getMutableQuestions,
  getQuestions,
  valueOf,
  isSubPar,
  bmiSubtitle,
  getReportValue,
}
export default questions
