import React from 'react'
import styled from 'styled-components'

import check from '../../assets/check.svg'

const Box = styled.div`
  width: 15px;
  height: 15px;
  border: 2px solid black;
  color: white;
  margin-right: 4px;
  border-radius: 4px;
  transition: all 0.1s ease-in;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
`

const Mark = styled.img`
  height: 10px;
  margin: auto;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`
const Keystroke = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  font-size: 14px;

  *:focus & {
    opacity: 0.5;
  }
`

const ExpandingContainer = styled.div`
  display: flex;
  background: black;
  border-radius: 50%;

  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;

  ${(p) => !p.visible && 'transform: scale(0);'}
  ${(p) => p.visible && 'transition: transform 0.1s ease-in;'}
`

class Checkbox extends React.PureComponent {
  render() {
    const { checked, keystroke } = this.props
    return (
      <Container>
        <Keystroke>{keystroke}</Keystroke>

        <Box>
          <ExpandingContainer visible={checked}>
            <Mark checked={checked} src={check} alt="check mark" />
          </ExpandingContainer>
        </Box>
      </Container>
    )
  }
}

export default Checkbox
