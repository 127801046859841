import React from 'react'
import { t } from '../lib/i18n'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { footerText, currentEntry, survey, meta } from '../model/selectors'
import { updateCustomFooter } from '../model/actions'
import Header from './Header'
import Divider from '../elements/Divider'
import { getSummary, getRecommendations } from '../lib/healthAge'
import { getBmi } from '../lib/bmi'

import appleImg from '../assets/appletape.jpg'
import graybox from '../assets/graybox.svg'
import redCheck from '../assets/fa-check.svg'

const Footer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`
const FooterInput = styled.textarea`
  border: none;
  font-size: 15px;
  text-align: center;
  width: 80%;
  resize: none;
  outline: none;
  background: transparent;

  .ReportView:hover & {
    border: 1px solid #ccc;
  }
  &:focus {
    border: 1px solid #ccc;
    background: white;
  }
`

const LayerContainer = styled.div`
  position: relative;
`

// const printView = query.parse(window.location.search).view === 'print'
const ContentLayer = styled.div`
  position: relative;
`
const BgLayer = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

const AgeValue = ({ value, desc, subdesc }) => {
  return (
    <div className="ageValue">
      <LayerContainer>
        <BgLayer src={graybox} />
        <ContentLayer className="ageValue__value">{value || 0}</ContentLayer>
      </LayerContainer>

      <div className="ageValue__descc">
        <div className="ageValue__desc">{desc}</div>
        <div className="ageValue__subdesc">{subdesc}</div>
      </div>
    </div>
  )
}

const Rcmd = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  page-break-inside: avoid;
  break-inside: avoid;
  padding-bottom: 10px;
  color: ${(p) => (p.inactive ? 'rgba(255, 255, 250, 0)' : 'black')};
  text-shadow: 0 0 0 ${(p) => (p.inactive ? 'gray' : 'none')};

  @media print and (-webkit-min-device-pixel-ratio: 0) {
    & {
      color: ${(p) => (p.inactive ? 'gray' : 'black')};
      -webkit-print-color-adjust: exact;
    }
  }
`
const RcmdTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
`
const RcmdDesc = styled.div`
  font-size: 14px;

  & ul {
    margin: 0;
    padding-left: 23px;
  }
`
const RedCheck = styled.img`
  color: red;
  font-size: 21px;
  align-self: center;
  margin-right: 4px;
  height: 19px;
`

const Recommendation = ({ title, subtitle, subPar }) => {
  return (
    <Rcmd inactive={!subPar}>
      <RcmdTitle>
        {subPar && <RedCheck src={redCheck} />}
        {title}
      </RcmdTitle>
      <RcmdDesc>{subtitle}</RcmdDesc>
    </Rcmd>
  )
}

const RecommendationsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
const Column = styled.div`
  width: 48%;
`

const Recommendations = ({ recommendations }) => {
  console.log('render recommendations')
  let rcmds
  if (recommendations) {
    rcmds = recommendations.map((rcmd) => {
      return (
        <Recommendation
          key={rcmd.get('title')}
          title={rcmd.get('title')}
          subtitle={rcmd.get('subtitle')}
          subPar={rcmd.get('subPar')}
        />
      )
    })
  }
  return (
    <RecommendationsContainer>
      <Column>{rcmds.slice(0, 6)}</Column>
      <Column>{rcmds.slice(6, 12)}</Column>
    </RecommendationsContainer>
  )
}

const height = (state) => meta(state).get('height')
const weight = (state) => meta(state).get('weight')

const recommendations = createSelector(
  [height, weight, survey],
  (height, weight, survey) => {
    return getRecommendations(survey)(getBmi(height, weight))
  },
)

const BoundRecommendations = connect((state) => {
  return {
    recommendations: recommendations(state),
  }
})(Recommendations)

const Container = styled.div`
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  background-color: white;
  padding: 35px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 11in; */
  width: 8.5in;
  box-sizing: border-box;

  .not_en & {
    padding: 24px;
    padding-top: 15px;
    /* height: 297mm; */
    width: 210mm;
  }

  @media print {
    & {
      height: auto !important;
      width: auto !important;
    }
  }
`

const DescriptionText = styled.div`
  font-size: 17px;
  width: 100%;

  .locale__de & {
    font-size: 16px;
  }

  .locale__ru & {
    font-size: 15px;
  }
`

const MetaFieldsComponent = ({ name, currentAge, healthAge, potentialAge }) => {
  return (
    <div className="metaFields">
      <span className="field fullWidth">
        <span className="fieldLabel">{t(`Customized report for`)}</span>
        <LayerContainer className="fieldValue">
          <BgLayer src={graybox} />
          <ContentLayer>{name}</ContentLayer>
        </LayerContainer>
      </span>

      <div className="ageValues">
        <AgeValue
          value={currentAge}
          desc={t(`Your Current Age`)}
          subdesc={t(`according to your birth record`)}
        />

        <AgeValue
          value={healthAge.toFixed(0)}
          desc={t(`Your Health Age`)}
          subdesc={t(`according to your health habits`)}
        />

        <AgeValue
          value={potentialAge.toFixed(0)}
          desc={t(`Your Potential Health Age`)}
          subdesc={t(`if you practiced all health habits`)}
        />
      </div>
    </div>
  )
}

const MetaFields = connect((state) => {
  const summary = getSummary(currentEntry(state))

  return {
    currentAge: summary.get('age'),
    healthAge: summary.get('healthAge'),
    potentialAge: summary.get('potentialAge'),
    name: summary.get('name'),
  }
})(MetaFieldsComponent)

const BoundFooterComponent = ({ updateFooter, footerText }) => {
  return (
    <Footer>
      <FooterInput
        rows={footerText.split('\n').length}
        value={footerText}
        onChange={(e) => updateFooter(e.target.value)}
      />
    </Footer>
  )
}

const BoundFooter = connect(
  // mapState
  (state) => {
    return { footerText: footerText(state) }
  },
  // eventMap
  { updateFooter: updateCustomFooter },
)(BoundFooterComponent)

const ReportView = ({ className }) => {
  return (
    <Container className={`ReportView ${className}`}>
      <Header />

      <DescriptionText>
        <Divider thick />

        <p>
          {t(
            `More than ever before, scientific research is revealing the fact that premature aging and disability are largely the result of our lifestyle. Poor eating habits, using harmful substances, and not getting enough sleep or exercise can make us old before our time.`,
          )}
          <img className="appleImg" src={appleImg} alt="" />
        </p>

        <p>
          {t(
            `Several large research studies, including the well-known Alameda County Study and the Adventist Health Studies continue to reveal a clear relationship between the health habits listed below and one’s risk of death. Individuals practcing all of these healthy habits may influence their longevity by nearly 30 years.`,
          )}
        </p>
      </DescriptionText>

      <Divider />

      <MetaFields />

      <Divider />

      <div className="rcmd">
        <h2 className="rcmd__title">{t(`Recommendations`)}</h2>
        <BoundRecommendations />
      </div>

      <BoundFooter />
    </Container>
  )
}

export default ReportView
