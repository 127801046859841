import unit from './converter/minutia'
import { round } from './util.js'

export const isValidWeight = (value) => {
  return !!unit(value).to('kg')
}

export const isValidHeight = (value) => {
  console.log('isValidWeight', value, !!unit(value).to('kg'))
  return !!unit(value).to('m')
}

const getBmi = (heightString, weightString, richErr) => {
  const height = unit(heightString).to('m')
  const weight = unit(weightString).to('kg')

  const rawBmi = weight / height ** 2
  const result = round(rawBmi, 1)

  if (richErr) {
    return {
      value: result,
      height: !!height,
      weight: !!weight,
    }
  }

  return result
}

const bmiFromEntry = (entry) => {
  const height = entry.getIn(['meta', 'height'])
  const weight = entry.getIn(['meta', 'weight'])

  return getBmi(String(height), String(weight))
}

const calcBmi = (s) => {
  const entryId = s.get('currentEntry')
  const currentEntry = s.getIn(['entries', entryId])
  return bmiFromEntry(currentEntry)
}

export { getBmi, calcBmi, bmiFromEntry }
export default getBmi
