const isElectron = require('is-electron')

const resetIntercom = (info) => {
  console.log('resetting intercom session.')
  info && info.user_id && console.log('new key =', info.user_id)

  window.Intercom('shutdown')
  window.Intercom('boot', {
    app_id: 'qgr7dwey',
    ...info,
  })
}

// kill switch
export const RESET_STATE = 'RESET_STATE'
export function resetState() {
  return { type: RESET_STATE }
}

// app state
export const VIEW_SURVEY = 'VIEW_SURVEY'
export function viewSurvey() {
  return { type: VIEW_SURVEY }
}

export const VIEW_REPORT = 'VIEW_REPORT'
export function viewReport() {
  return { type: VIEW_REPORT }
}

export const TOGGLE_VIEW = 'TOGGLE_VIEW'
export function toggleView() {
  return { type: TOGGLE_VIEW }
}

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export function toggleSidebar() {
  return { type: TOGGLE_SIDEBAR }
}

export const FINISH_WELCOME = 'FINISH_WELCOME'
export function finishWelcome(key, location) {
  resetIntercom({ user_id: key })
  window.Intercom('trackEvent', 'logged-in', { location })
  if (isElectron()) {
    const { ipcRenderer } = window.require('electron')
    ipcRenderer.send('login-status', {
      loggedIn: true,
      key,
    })
  }
  return { type: FINISH_WELCOME, key }
}

export const OFFLINE = 'OFFLINE'
export function offline() {
  return { type: OFFLINE }
}
export const ONLINE = 'ONLINE'
export function online() {
  return { type: ONLINE }
}
export const OFFLINE_ENABLE = 'OFFLINE_ENABLE'
export function offlineEnable() {
  window.Intercom('trackEvent', 'enabled-offline-mode')
  return { type: OFFLINE_ENABLE }
}
export const OFFLINE_DISABLE = 'OFFLINE_DISABLE'
export function offlineDisable() {
  window.Intercom('trackEvent', 'disabled-offline-mode')
  return { type: OFFLINE_DISABLE }
}
export const SERVER_CHECKIN = 'SERVER_CHECKIN'
export function serverCheckIn(userLevel, latestVersion) {
  return { type: SERVER_CHECKIN, userLevel, latestVersion }
}
export const SERVER_CHECKIN_FAILED = 'SERVER_CHECKIN_FAILED'
export function serverCheckInFailed() {
  return { type: SERVER_CHECKIN_FAILED }
}
export const LOGOUT = 'LOGOUT'
export function logout() {
  resetIntercom()
  if (isElectron()) {
    const { ipcRenderer } = window.require('electron')
    ipcRenderer.send('login-status', {
      loggedIn: false,
    })
  }
  return { type: LOGOUT }
}

export const UPDATE_SEARCH_QUERY = 'UPDATE_SEARCH_QUERY'
export function updateSearchQuery(query) {
  return { type: UPDATE_SEARCH_QUERY, query }
}

// entries state
export const NEW_ENTRY = 'NEW_ENTRY'
export const DELETE_ENTRY = 'DELETE_ENTRY'
export const SELECT_ENTRY = 'SELECT_ENTRY'
export const UPDATE_META = 'UPDATE_META'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const DELETE_ALL_ENTRIES = 'DELETE_ALL_ENTRIES'
export const DO_MIGRATION = 'DO_MIGRATION'
export const TOGGLE_INTEREST = 'TOGGLE_INTEREST'
export const DELETE_CURRENT_ENTRY = 'DELETE_CURRENT_ENTRY'

export function newEntry() {
  window.Intercom('trackEvent', 'new-entry')
  return { type: NEW_ENTRY }
}
export function deleteCurrentEntry() {
  return { type: DELETE_CURRENT_ENTRY }
}
export function deleteEntry(id) {
  return { type: DELETE_ENTRY, id }
}
export function selectEntry(id) {
  return { type: SELECT_ENTRY, id }
}
export function updateMeta(field, value) {
  return { type: UPDATE_META, field, value }
}
export function updateSurvey(question, selection) {
  return { type: UPDATE_SURVEY, question, selection }
}
export function deleteAllEntries() {
  return { type: DELETE_ALL_ENTRIES }
}
// can be large
export function doMigration(info) {
  return { type: DO_MIGRATION, ...info }
}
export function toggleInterest(slug) {
  return { type: TOGGLE_INTEREST, slug }
}
// can be large
export const ADD_ENTRIES = 'ADD_ENTRIES'
export function addEntries(entries) {
  return { type: ADD_ENTRIES, entries }
}

// customization
export const UPDATE_CUSTOM_FOOTER = 'UPDATE_CUSTOM_FOOTER'
export function updateCustomFooter(value) {
  return { type: UPDATE_CUSTOM_FOOTER, value }
}
