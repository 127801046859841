import React, { useState } from 'react'
import { Map, fromJS } from 'immutable'
import utf8 from 'utf-8'
import base64 from 'base64-arraybuffer'
import yoinkit from 'yoinkit'
import stringify from 'csv-stringify/lib/sync'
import parse from 'csv-parse/lib/es5/sync'
import styled from 'styled-components'

import { iStrings } from './strings.js'
import { config } from '../model/globalConfig'
import helpIcon from '../assets/help-black.svg'

const errors = new Set()
const err = (msg) => {
  if (!errors.has(msg)) {
    console.log(msg)
    errors.add(msg)
  }
}

let stringsCache = null

const addString = (string) => {
  stringsCache = (stringsCache || iStrings).set(string, Map())
  console.log(stringsCache.size)
}

window.getStrings = () => {
  return JSON.stringify((stringsCache || iStrings).toJS(), null, 2)
}

const t = (string, fill) => {
  const locale = config('locale')

  // warn if this translation isn't in the database
  if (!iStrings.get(string)) {
    err(`missing strings entry for ${string}`)
    addString(string)
  } else if (!iStrings.getIn([string, locale]) && locale !== 'en') {
    err(`string not translated to ${locale}: ${string}`)
  }

  // use the default string if this one hasn't been translated yet
  let translatedString = iStrings.getIn([string, locale]) || string

  if (typeof fill === 'function') {
    translatedString = fill(translatedString)
  } else {
    if (translatedString.indexOf('##HELP_ICON##') !== -1) {
      translatedString = translatedString.replace(
        '##HELP_ICON##',
        `<img src="${helpIcon}" style="height: 22px;position: relative;top: 3px;" />`,
      )
      return <span dangerouslySetInnerHTML={{ __html: translatedString }} />
    }

    translatedString = translatedString.replace(/##.+##/, fill)
  }

  return translatedString
}

const showCsv = (locale) => {
  // make a line for each entry
  const lines = (stringsCache || iStrings)
    .map((v, k) => [k, v.get(locale)])
    .valueSeq()
    .toJS()

  const output = stringify(lines, {
    columns: ['English', 'Your Translation'],
    header: true,
  })

  const data = base64.encode(utf8.setBytesFromString(output))
  console.log('exporting', yoinkit.canYoinkit())

  yoinkit({
    data: data,
    encoding: 'base64',
    contentType: 'text/plain',
    filename: 'strings.csv',
  })
}

const mergeCsv = (locale, text) => {
  let result = parse(text, {
    columns: ['en', 'target'],
  })
  result = result.filter((r) => r.en)
  result.shift()
  result = fromJS(result)

  result.forEach((translation) => {
    console.log(
      'translation',
      translation.get('en'),
      locale,
      translation.get('target'),
      translation.toJS(),
    )
    stringsCache = (stringsCache || iStrings).setIn(
      [translation.get('en'), locale],
      translation.get('target'),
    )
  })

  console.log(stringsCache.toJS())
}

const downloadJson = () => {
  let json = (stringsCache || iStrings).toJS()
  json = JSON.stringify(json, null, 2)
  json = base64.encode(utf8.setBytesFromString(json))
  yoinkit({
    data: json,
    encoding: 'base64',
    contentType: 'application/json',
    filename: 'strings.json',
  })
}

const StringsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ShowStrings = () => {
  const [csv, setCsv] = useState('')
  const [locale, setLocale] = useState(config('locale'))

  return (
    <StringsContainer>
      <input
        type="text"
        value={locale}
        onChange={(e) => setLocale(e.target.value)}
      />
      <textarea
        onChange={(e) => setCsv(e.target.value)}
        placeholder="Paste CSV here."
        value={csv}
      />
      <br />
      <button onClick={() => showCsv(locale)}>
        Download CSV for {locale} locale
      </button>
      <button onClick={() => mergeCsv(locale, csv)}>
        Merge pasted CSV for {locale} locale
      </button>
      <button onClick={downloadJson}>Download JSON strings</button>
    </StringsContainer>
  )
}

export { t, ShowStrings }
