import React from 'react'
import styled from 'styled-components'
import isElectron from 'is-electron'
import { connect } from 'react-redux'

import Entries from './Entries'
// import Header from './Header'
import { exportCsv, exportHer } from '../lib/csvExport'
import { entries } from '../model/selectors'
import { store, getHer } from '../model/store'
import { newEntry, deleteCurrentEntry } from '../model/actions'
import { t } from '../lib/i18n'

const Container = styled.div`
  flex: none;
  width: 300px;
  height: 100%;
  ${'' /* flex-grow: 0; */} background: white;
  ${'' /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13); */} display: flex;
  flex-direction: column;
  overflow: hidden;
  ${(p) => p.electron && 'border-right: 1px solid #aaa9aa;'}
  ${(p) => !p.electron && 'box-shadow: 1px 0px 6px -1px #0006;'}
  ${'' /* padding-bottom: 50px; */} box-sizing: border-box;
  ${'' /* transition: 0.2s right; */} ${'' /* z-index: 2; */};
`

const Footer = styled.footer``

const Sidebar = ({ className, newEntry, deleteCurrentEntry }) => {
  const electron = isElectron()

  return (
    <Container className={className} electron={electron}>
      <Entries />
      <Footer className="toolbar toolbar-footer">
        <div className="toolbar-actions">
          <div className="btn-group">
            <button className="btn btn-default" onClick={newEntry}>
              <span className="icon icon-plus" />
            </button>
            <button className="btn btn-default" onClick={deleteCurrentEntry}>
              <span className="icon icon-minus" />
            </button>
          </div>
          {!electron && (
            <div className="btn-group pull-right">
              <button
                className="btn btn-default"
                onClick={() => {
                  exportHer(getHer())
                }}
                title={t(`Download HER file for use with the desktop app.`)}
              >
                <span className="icon icon-download icon-text" />
                HER
              </button>
              <button
                className="btn btn-default"
                onClick={() => {
                  exportCsv(entries(store.getState()))
                }}
                title={t(`Export CSV (generic spreadsheet)`)}
              >
                <span className="icon icon-export icon-text" />
                CSV
              </button>
            </div>
          )}
          {electron && (
            <button
              title={t(`Export CSV (generic spreadsheet)`)}
              className="btn btn-default pull-right"
              onClick={() => {
                exportCsv(entries(store.getState()))
              }}
            >
              <span className="icon icon-export icon-text" />
              {t(`Export CSV`)}
            </button>
          )}
        </div>
      </Footer>
    </Container>
  )
}

const eventMap = {
  newEntry,
  deleteCurrentEntry,
}

export default connect(
  null,
  eventMap,
)(Sidebar)
// export default Sidebar
