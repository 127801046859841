import { createStore } from 'redux'
import { toJSON, fromJSON } from 'transit-immutable-js'
import dragDrop from 'drag-drop'
import { devToolsEnhancer } from 'redux-devtools-extension'
import isElectron from 'is-electron'
// import createRavenMiddleware from 'raven-for-redux'
// import { fromJS } from 'immutable'

// import { doMigration } from './actions'
import { toCsv } from '../lib/csvExport'
import { saveState, loadState } from './localStorage'
import { reduce as reducer } from './reducers/'
import { params } from '../lib/query'
import { fullUrl } from '../lib/util'
import { passedWelcome, licenceKey } from './selectors'
import { addEntries } from './actions'
import { getLockedLocale } from '../lib/specialCases.js'
import { config } from './globalConfig'
import debounce from '../lib/p-debounce'
// import * as Sentry from '@sentry/browser'
// import { DO_MIGRATION, ADD_ENTRIES } from './actions'

const VERSION = 23

let state = loadState(VERSION)

if (isElectron() && params.file) {
  try {
    const fs = window.require('fs')
    const contents = fs.readFileSync(params.file, { encoding: 'utf8' })
    const data = fromJSON(contents)

    if (!(data.entries && data.customization)) {
      throw new Error('Missing required file entries.')
    }

    Object.assign(state, {
      entries: data.entries,
      customization: data.customization,
    })
  } catch (e) {
    console.log(e)
    const { remote } = window.require('electron')
    remote.dialog.showErrorBox(
      'Error loading file.',
      'The file appears to be corrupted.',
    )
  }
}

// const ravenMiddleware = createRavenMiddleware(Raven, {
//   breadcrumbDataFromAction: (action) => {
//     if (![DO_MIGRATION, ADD_ENTRIES].includes(action.type)) {
//       try {
//         const data = {}
//         Object.keys(action).forEach((key) => {
//           data[key] = JSON.stringify(action[key])
//         })
//         return data
//       } catch (e) {
//         return String(e)
//       }
//     }
//   },
//   stateTransformer: (state) => fromJS(state.present).toJS(),
//   getTags: (state) => {},
// })

// const middleware = composeWithDevTools(applyMiddleware(ravenMiddleware))

const store = createStore(reducer, state, devToolsEnhancer())

const dumpState = debounce((state) => {
  state.past = []
  state.future = []

  const newEntries = state.present.entries.get('entries').map((entry) => {
    return entry
      .setIn(['meta', 'name'], '[redacted]')
      .setIn(['meta', 'email'], '[redacted]')
      .setIn(['meta', 'address'], '[redacted]')
      .setIn(['meta', 'phone'], '[redacted]')
      .setIn(['meta', 'zip'], '[redacted]')
  })

  state.present.entries = state.present.entries.set('entries', newEntries)
  return window
    .fetch(fullUrl(`/app/dumpState`), {
      credentials: 'include',
      method: 'POST',
      body: toJSON(state),
    })
    .then((r) => r.raw())
}, 5000)

// save everything to LocalStorage
store.subscribe(
  debounce(() => {
    const state = store.getState().present

    // save the state
    saveState(state)

    // save the version
    window.localStorage.setItem('haVersion', VERSION)

    // dump state to server
    if (params.dumpstate) {
      console.log('dumping state')
      dumpState(state)
    }
  }, 1000),
)

export const getHer = () => {
  const state = store.getState()
  const pickedState = {
    entries: state.present.entries,
    customization: state.present.customization,
    version: VERSION,
  }

  return toJSON(pickedState)
}

if (isElectron()) {
  const { ipcRenderer } = window.require('electron')

  // user requested save
  ipcRenderer.on('save', () => {
    const stateString = getHer()
    ipcRenderer.send('save-reply', { store: stateString })
  })

  // locale was changed in another
  ipcRenderer.on('change-locale', (e, locale) => {
    config('locale', locale)
    window.location.reload()
  })
  ipcRenderer.send('locale', { locale: config('locale') })

  // state query command
  const sendLoginStatus = () => {
    const state = store.getState()
    ipcRenderer.send('login-status', {
      loggedIn: passedWelcome(state),
      key: licenceKey(state),
    })
  }
  ipcRenderer.on('get-login-status', sendLoginStatus)
  sendLoginStatus()

  // emit change commands
  let lastState = store.getState().present
  store.subscribe(() => {
    let state = store.getState().present

    if (
      state.entries !== lastState.entries ||
      state.customization !== lastState.customization
    ) {
      ipcRenderer.send('modified')
    }
    lastState = state
  })
}

if (params.dumpcsv) {
  const entries = store.getState().present.entries.get('entries')
  const csv = toCsv(entries)
  window.fetch(fullUrl(`/app/dumpState`), {
    credentials: 'include',
    method: 'POST',
    body: csv,
  })
}

// if the license key is "china", then the locale must be zh
// make it more difficult for script kiddies to mess around ;)
const enforceLanguageLock = () => {
  const state = store.getState()
  const key = licenceKey(state)
  const forcedLocale = getLockedLocale(key)
  const currentLocale = config('locale')
  if (forcedLocale && currentLocale !== forcedLocale) {
    window.setTimeout(() => {
      config('locale', forcedLocale)
      window.location.reload()
    }, 1000)
  }
}
store.subscribe(enforceLanguageLock)
enforceLanguageLock()

// merge in dropped files
dragDrop(document.body, function(files) {
  // `files` is an Array!
  files.forEach(function(file) {
    console.log('got a new file', file.name)

    var reader = new FileReader()
    reader.addEventListener('load', function(e) {
      try {
        const entries = fromJSON(e.target.result).entries.get('entries')
        if (!entries) {
          throw new Error('No entries found!')
        }
        store.dispatch(addEntries(entries))
      } catch (e) {
        alert('Error reading file.  Only .her files are supported.')
      }
    })
    reader.addEventListener('error', function(err) {
      console.error('FileReader error' + err)
    })
    reader.readAsText(file)
  })
})

export { store }
