import React from 'react'
import autoBind from 'auto-bind'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { getJson } from '../lib/util'

import { Container, Loading } from './elements'

const Form = styled.form`
  display: flex;
`

const Error = styled.div`
  color: red;
  font-size: 17px;
  margin-bottom: 4px;
`

const BackContainer = styled.div`
  font-size: 15px;
  position: absolute;
  top: 6px;
  left: 10px;
  color: gray;

  & > a {
    color: inherit;
  }
`

const Input = styled.input`
  font-size: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => (p.invalid ? 'red' : 'darkgray')}
  flex-grow: 1;
`

const Title = styled.h3`
  font-size: 25px;
  margin-top: 0;
`

const Subtitle = styled.p`
  font-size: 20px;
  margin-top: 0;
`

const Button = styled.button`
  margin-left: 5px;
`

const AlwaysBlue = styled.a`
  color: blue;
`

const Footer = styled.p`
  margin-bottom: 0;
`

const ResetContainer = ({ children, noBack, ...props }) => {
  return (
    <Container {...props}>
      {noBack ? (
        ''
      ) : (
        <BackContainer>
          <Link to="/">back</Link>
        </BackContainer>
      )}
      {children}
    </Container>
  )
}

const ResetDisplay = ({ isInvalid, email, onChange, submitClick, error }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    submitClick()
  }

  return (
    <ResetContainer width="435px">
      <Title>Forgot your license key?</Title>
      <Subtitle>
        Enter your email address below and we'll email it to you.
      </Subtitle>
      {error && <Error>{error}</Error>}
      <Form onSubmit={handleSubmit}>
        <Input
          invalid={!!error}
          name="email"
          type="text"
          value={email}
          onChange={onChange}
        />
        <Button type="submit">Remind Me</Button>
      </Form>
      <Footer>
        Need to buy a licence?{' '}
        <AlwaysBlue
          href="https://www.healthexporesources.com/product-page/health-age"
          target="_blank"
        >
          Click Here
        </AlwaysBlue>
      </Footer>
    </ResetContainer>
  )
}

class Forgot extends React.Component {
  constructor() {
    super()
    autoBind(this)

    this.state = {
      email: '',
      error: null,
      view: 'form',
    }
  }
  notifyInvalid(e) {
    this.setState({ invalidKey: true })
  }
  updateKey(e) {
    this.setState({ email: e.target.value, invalidKey: false })
  }
  handleSubmit() {
    if (!this.state.email) {
      return this.setState({
        error: 'Invalid email address',
      })
    }

    this.setState({ view: 'loading' }, () => {
      getJson(`/app/api/forgot?email=${this.state.email}`)
        .then((r) => {
          if (r.status === 'success') {
            this.setState({
              error: '',
              view: 'success',
            })
          } else if (r.error) {
            this.setState({
              view: 'form',
              error: r.error,
            })
          } else {
            this.setState({
              view: 'form',
              error: 'Unknown error.',
            })
          }
        })
        .catch((e) => {
          console.log(e)
          this.setState({
            view: 'form',
            error: 'Error contacting server.  Are you online?',
          })
        })
    })
  }
  render() {
    if (this.state.view === 'loading') {
      return <Loading msg="Sending Email..." />
    }

    if (this.state.view === 'success') {
      return (
        <ResetContainer width="510px">
          <p>
            Check your inbox. An email is already on it's way to{' '}
            <code>{this.state.email}</code> with a list of your license keys.
          </p>

          <p>
            If you continue to have problems logging in, don't hesitate to
            contact us at{' '}
            <a href="mailto:support@discoverhealthage.com">
              support@discoverhealthage.com
            </a>
            .
          </p>
        </ResetContainer>
      )
    }

    return (
      <ResetDisplay
        isInvalid={this.state.invalidKey}
        email={this.state.email}
        onChange={this.updateKey}
        submitClick={this.handleSubmit}
        loading={this.state.loading}
        error={this.state.error}
      />
    )
  }
}

export default Forgot
