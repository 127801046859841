// external libs
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import query from "query-string";
import moment from "moment";
import isElectron from "is-electron";
// import insertCss from 'insert-css'
import { HashRouter as Router, Route, withRouter } from "react-router-dom";

// local libs
import { config } from "../model/globalConfig";
import {
  passedWelcome,
  sidebarVisible,
  offlineUntil,
  connectivity,
  offlineMode
} from "../model/selectors";

// local components
import WelcomeScreen, { Forgot } from "../WelcomeScreen";
import ReportView from "../ReportView";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Admin from "../Admin";
import MainView from "./MainView";
import Expired from "./Expired";
import IntercomUpdater from "./IntercomUpdater";
import { ShowStrings } from "../lib/i18n.js";
import Translation from "../Translation";
// import RavenUpdater from './RavenUpdater'

// web-specific components
import WebHeader from "../WebHeader";

// resources
// import bgImg from '../assets/pagebg.jpg'

const printView = query.parse(window.location.search).view === "print";

const MOBILE_BRK = "960px";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(222, 222, 222);
  ${"" /* background: url('${bgImg}'); */}
  ${"" /* background-size: cover; */}
  ${"" /* background-position: center; */}
  ${"" /* padding-top: 40px; */}
  ${"" /* box-sizing: border-box; */}
  padding: 0;
  ${"" /* transition: 0.2s transform; */}
  ${"" /* padding-right: 0; */}
  ${"" /* height: 100vh; */}
  ${p =>
    p.disabled && "filter: brightness(0.4) blur(5px);"} transition: filter 0.2s;
  ${p => p.disabled && "pointer-events: none;"} @media print {
    display: none;
  }

  @media (min-width: ${MOBILE_BRK}) {
    ${"" /* padding-top: 50px; */};
  }
`;

const PrintContainer = styled(ReportView)`
  /* initially hidden but covering the whole page */
  display: ${printView ? "block" : "none"};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  /* show the printContainer and hide everything else when printing */
  @media print {
    display: block;
    box-shadow: none;
  }
`;

const PageContainer = styled.div`
  ${
  "" /* transform: ${(p) => p.sidebarVisible && 'TranslateX(-300px)'}; */
  } display: flex;
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  overflow: scroll;
  ${"" /* background: url("${bgImg}"); */}
  ${"" /* background-position: center; */}
  ${"" /* background-size: cover; */}

${"" /* blur */}
  ${
  "" /* background: radial-gradient(
    circle at 50% 25%,
    hsla(202, 60%, 72%, 1) 0,
    #022940 100%
  ); */
  }

${p =>
    p.showBg &&
    `background: radial-gradient(
  circle at 50% 25%,
  #f2fcfe 0,
  #1c92d2 100%
);`}

${"" /* Dawn */}
${
  "" /* background: radial-gradient(
 circle at 50% 25%,
 #ffd89b 0,
 #19547b 100%
); */
  }
}
`;

const PositionedSidebar = styled(Sidebar)`
  ${"" /* transform: ${(p) => p.sidebarVisible && 'TranslateX(-300px)'};
  transition: 0.2s transform; */};
  flex: none;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  min-height: 0;
`;

const Layout = ({
  passedWelcome,
  sidebarVisible,
  entry,
  expired,
  online,
  offlineMode,
  conectivity
}) => {
  if (!passedWelcome) {
    return <WelcomeScreen />;
  }

  const locale = config("locale");
  const notEn = locale !== "en" ? "not_en" : "";

  // we don't check for electron, because if we're in offline mode then we're not on electron
  // we don't check for connectivity, because a server check in resets the exiration
  if (offlineMode && expired) {
    return <Expired />;
  }

  const electron = isElectron();

  return (
    <div className={`locale__${locale} ${notEn}`}>
      <Container disabled={!online && !offlineMode && !isElectron()}>
        {electron ? <Header /> : <WebHeader />}
        <Content>
          {electron && sidebarVisible && <PositionedSidebar />}

          <PageContainer
            sidebarVisible={sidebarVisible}
            className="scrollingContainer"
            showBg={!electron}
          >
            <MainView />
          </PageContainer>
          {!electron && sidebarVisible && <PositionedSidebar />}
        </Content>
      </Container>
      <PrintContainer />
    </div>
  );
};

const mapState = state => {
  return {
    passedWelcome: passedWelcome(state),
    sidebarVisible: sidebarVisible(state),
    expired: moment().isAfter(offlineUntil(state)),
    online: connectivity(state),
    offlineMode: offlineMode(state)
  };
};
const ReduxLayout = connect(mapState)(Layout);

const injectorMapState = state => {
  return {
    offlineMode: offlineMode(state)
  };
};

const OfflineInjector = withRouter(
  connect(injectorMapState)(({ location, children, offlineMode }) => {
    const onlineRequired =
      location.pathname.includes("/admin") ||
      location.pathname.includes("/forgot") ||
      (!offlineMode && !isElectron());
    console.log("onlineRequired", onlineRequired);
    if (onlineRequired) {
      document.body.classList.remove("hide-offline-ui");
    } else {
      document.body.classList.add("hide-offline-ui");
    }
    return <div>{children}</div>;
  })
);

const RoutedLayout = () => {
  return (
    <Router>
      <OfflineInjector>
        <Route exact path="/" component={ReduxLayout} />
        <Route path="/admin" component={Admin} />
        <Route path="/forgot" component={Forgot} />
        <Route path="/strings" component={ShowStrings} />
        <Route path="/translation" component={Translation} />
        <IntercomUpdater />
      </OfflineInjector>
    </Router>
  );
};

export default RoutedLayout;
