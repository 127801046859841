import React, { useState } from 'react'
import styled from 'styled-components'

import { getJson } from '../lib/util'
import KeyList from './KeyList'
import KeyInfo from './KeyInfo'
import SaveBar from './SaveBar'

import '../assets/resizer.css'

const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

const Admin = () => {
  const [currentKey, setCurrentKey] = useState(null)
  const [loadingKeyInfo, setLoadingKeyInfo] = useState(false)
  const [keyInfo, setKeyInfo] = useState(null)
  const [saved, setSaved] = useState(true)

  let keyInfoDisplay = null
  if (loadingKeyInfo) {
    keyInfoDisplay = <h1 style={{ margin: '50px' }}>Loading {currentKey}</h1>
  } else {
    keyInfoDisplay = (
      <KeyInfo
        info={keyInfo}
        onChange={(newInfo) => {
          setKeyInfo(newInfo)
          setSaved(false)
        }}
      />
    )
  }

  return (
    <Container>
      <KeyList
        setCurrentKey={async (newKey) => {
          setLoadingKeyInfo(true)
          setCurrentKey(newKey)
          const info = await getJson('/admin/keys/' + newKey)
          setKeyInfo(info)
          setLoadingKeyInfo(false)
        }}
        currentKey={currentKey}
      />
      {keyInfoDisplay}
      <SaveBar
        info={keyInfo}
        saved={loadingKeyInfo || saved}
        onSave={(savedInfo) => {
          if (keyInfo.key === savedInfo.key) {
            setSaved(true)
          }
        }}
      />
    </Container>
  )
}

export default Admin
