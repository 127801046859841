import { store } from '../model/store'
import { online, offline } from '../model/actions'

import '../assets/offline-theme-default.css'
import '../assets/offline-language-english.css'
import 'offline-js'

window.Offline.options = {
  // Should we check the connection status immediatly on page load.
  checkOnLoad: false,

  // Should we monitor AJAX requests to help decide if we have a connection.
  interceptRequests: false,

  // Should we store and attempt to remake requests which fail while the connection is down.
  requests: false,
}

window.Offline.on('down', function () {
  console.log('internet down')
  store.dispatch(offline())
})

window.Offline.on('up', () => {
  console.log('internet up!')
  store.dispatch(online())
})
