import data from './agedb.json'

// round a number to be within the given range
const normalize = (number: number, min: number, max?: number) => {
  if (number < min) { number = min }

  if (max && number > max) {
    number = max
  }

  return number
}

// get the age vector for a given score
const getAgeVector = (score: number, age: number) => {

  const scoreLookup = normalize(score, 0.5, 12)
  const ageLookup = normalize(age, 16, 80) - 16

  // normalization guarantees that we will always get an array of numbers 
  const scoreTable: number[] = (data as any)[scoreLookup.toString()]
  const vector = scoreTable[ageLookup]

  return vector
}

// get the health age of a person given a real age and a score
const lookupAge = (realAge: number, score: number) => {

  const age = realAge + getAgeVector(score, realAge)
  return normalize(age, 0)
}

export { getAgeVector, lookupAge }
