import { Map, OrderedMap, List, fromJS } from 'immutable'
import { v1 as uid } from 'uuid'

import { getBmi } from '../../lib/bmi'
import {
  // global actions
  NEW_ENTRY,
  DELETE_ENTRY,
  SELECT_ENTRY,
  DELETE_ALL_ENTRIES,
  DO_MIGRATION,
  // actions specific to current entry
  TOGGLE_INTEREST,
  UPDATE_META,
  UPDATE_SURVEY,
  DELETE_CURRENT_ENTRY,
  ADD_ENTRIES,
} from '../actions'

const QUESTION_COUNT = 12

const blankEntry = (id) => {
  console.log('blankEntry', id)
  return Map({
    id: id,
    meta: Map({}),
    survey: List(Array(QUESTION_COUNT)),
  })
}

const initialState = () => {
  // id for first entry
  const id = uid()

  return Map({
    currentEntry: id,
    entries: OrderedMap({
      [id]: blankEntry(id),
    }),
  })
}

const getWeightField = (height, weight) => {
  const bmi = getBmi(height, weight)

  let weightStatus // selectionID that would match the bmi value

  if (bmi >= 30) {
    // severely overweight
    weightStatus = 0
  } else if (bmi >= 25) {
    // moderately overweight
    weightStatus = 1
  } else if (bmi >= 18.5) {
    // healthy weight
    weightStatus = 3
  } else {
    // must be underweight
    weightStatus = 2
  }

  return weightStatus
}

function entryReducer(s, a) {
  switch (a.type) {
    case UPDATE_META:
      let meta = s.get('meta')

      // update the meta field
      meta = meta.set(a.field, a.value)

      // set the "how is your weight" value if we changed height or weight
      if (['height', 'weight'].includes(a.field)) {
        const height = String(meta.get('height'))
        const weight = String(meta.get('weight'))

        // notice it's "s", not "meta"
        const weightField = getWeightField(height, weight)
        if (typeof weightField === 'number') {
          s = s.setIn(['survey', 9], weightField)
        }
      }

      return s.set('meta', meta)

    case UPDATE_SURVEY:
      return s.setIn(['survey', a.question], a.selection)

    case TOGGLE_INTEREST:
      const current = s.getIn(['meta', a.slug])
      return s.setIn(['meta', a.slug], !current)

    default:
      return s
  }
}

const getNeighbors = (id, orderedMap) => {
  const entries = orderedMap.entrySeq()
  const centerIndex = entries.findKey((entry) => entry[0] === id)

  // negative indices are valid in Immutable.js.  We need them to return nothing.
  let previousEntryId
  if (centerIndex > 0) {
    previousEntryId = entries.get(centerIndex - 1)[0]
  }

  const nextEntryId = entries.get(centerIndex + 1, [undefined])[0]

  return [previousEntryId, nextEntryId]
}

// return the next entry ID, or the previous, or create a new entry and return it's ID
const selectNext = (id, state) => {
  const [previous, next] = getNeighbors(id, state.get('entries'))

  let newCurrentEntry = next || previous
  let ns = state

  if (!newCurrentEntry) {
    newCurrentEntry = uid()
    ns = ns.setIn(['entries', newCurrentEntry], blankEntry(newCurrentEntry))
  }

  return ns.set('currentEntry', newCurrentEntry)
}

const deleteEntry = (id, s) => {
  return selectNext(id, s).deleteIn(['entries', id])
}

export function reduce(s, a) {
  if (!s) {
    s = initialState()
  }

  // actions that act on the current entry
  const currentId = s.get('currentEntry')
  const currentEntry = s.getIn(['entries', currentId])
  s = s.setIn(['entries', currentId], entryReducer(currentEntry, a))

  // actions that act on the whole set
  switch (a.type) {
    case NEW_ENTRY:
      const u = uid()
      return s.setIn(['entries', u], blankEntry(u)).set('currentEntry', u)

    case DELETE_ENTRY:
      return deleteEntry(a.id, s)

    case DELETE_CURRENT_ENTRY:
      return deleteEntry(s.get('currentEntry'), s)

    case SELECT_ENTRY:
      return s.set('currentEntry', a.id)

    case DELETE_ALL_ENTRIES:
      return initialState() // cool shortcut

    case DO_MIGRATION:
      const { entries } = a
      return s.set('entries', s.get('entries').concat(fromJS(entries)))

    case ADD_ENTRIES:
      return s.set('entries', s.get('entries').concat(a.entries))

    default:
      return s
  }
}
