import { store } from '../model/store'
import { getSummary } from './healthAge'
import { sendEmail } from './emailReport'
import { shortcut } from './shortcut'

import { toggleView, newEntry, updateMeta, viewSurvey } from '../model/actions'
import { currentEntry } from '../model/selectors'
import { ActionCreators as undo } from 'redux-undo'
import { print } from './print'

const shortcuts = []

// email report
shortcuts.push(shortcut('mod+e', e => {
  e.preventDefault()

  const entry = currentEntry(store.getState())
  const summary = getSummary(entry)

  const updateFn = (key, value) => {
    return store.dispatch(updateMeta(key, value))
  }

  sendEmail(summary, updateFn)
}, true))

// toggle view
shortcuts.push(shortcut('esc', e => {
  e.preventDefault()

  store.dispatch(toggleView())
}, true))

// new entry
shortcuts.push(shortcut('mod+enter', e => {
  e.preventDefault()
  store.dispatch(viewSurvey())
  store.dispatch(newEntry())
}, true))

// undo
shortcuts.push(shortcut('mod+z', e => {
  e.preventDefault()
  store.dispatch(undo.undo())
}, true))

// redo
shortcuts.push(shortcut('mod+shift+z', e => {
  e.preventDefault()
  store.dispatch(undo.redo())
}, true))

// print
shortcuts.push(shortcut('mod+p', e => {
  e.preventDefault()
  print()
}, true))

export default shortcuts
