import { toJSON, fromJSON } from 'transit-immutable-js'
const isElectron = require('is-electron')

const loadState = (_version) => {
  // const version = Number(window.localStorage.getItem('haVersion'))
  let state = fromJSON(window.localStorage.getItem('healthageState_v4'))

  try {
    if (
      !state &&
      window.localStorage.getItem('healthageStateNG') &&
      window.localStorage.getItem('recover') !== 'no'
    ) {
      window.localStorage.setItem(
        'healthageState_v4',
        window.localStorage.getItem('healthageStateNG'),
      )
      state = fromJSON(window.localStorage.getItem('healthageState_v4'))
    }
  } catch (e) {
    // ignore bloated old state!
    console.log(e)
  }

  if (!state) {
    return
  }

  if (isElectron()) {
    delete state.entries
    delete state.customization
  }

  // online unless proven otherwise
  if (state.view) {
    state.view = state.view.set('online', true)
  }

  return state
}

const saveState = (state) => {
  return window.localStorage.setItem('healthageState_v4', toJSON(state))
}

// const getCryoEntries = () => {
//   try {
//     return loadCryo().get('entries').get('entries').delete('currentEntry').delete('entries')
//   } catch (e) {
//     return false
//   }
// }

// const getOldInfo = () => {
//   try {
//     const state = loadState().present
//     return {
//       entries: state.entries.get('entries').delete('currentEntry').delete('entries'),
//       mode: state.view.get('offlineMode'),
//       key: state.view.get('licenceKey'),
//       passedWelcome: state.view.get('passedWelcomeScreen')
//     }
//   } catch (e) {
//     return false
//   }
// }

// const migrateEntries = () => {
//   // check for state from a previous version
//   const oldInfo = getOldInfo()

//   if (oldInfo.entries) {
//     return oldInfo
//   } else {
//     return { entries: getCryoEntries() }
//   }
// }

export { loadState, saveState }
