import React from 'react'
import { connect } from 'react-redux'
import isElectron from 'is-electron'

// local libs
import { currentEntry } from '../model/selectors'
import { viewReport, newEntry } from '../model/actions'
import { t } from '../lib/i18n'
import { getSummary } from '../lib/healthAge'
import styled from 'styled-components'

// components
import SurveyView from '../SurveyView'
import { ButtonRow, ButtonGroup, Button } from '../elements/Button'

const AgeDisplay = styled.div`
  font-size: 30px;
  color: #000;
  opacity: 0.9;
  background: #fff;
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
`

// const DesktopAgeDisplay = styled.div`
//   display: flex;
//   justify-content: center;
//   font-size: 30px;
//   color: #000;
//   opacity: 0.9;
//   background: #fff;
//   padding: 5px;
//   margin-top: 5px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
// `

const AgeDisplayContainer = ({ age }) => {
  if (!age) {
    return <span />
  }

  return (
    <AgeDisplay>
      {t(`Health Age:`)} <strong>{age}</strong>
    </AgeDisplay>
  )
}

const BoundAgeDisplay = connect((state) => {
  let current = currentEntry(state)

  return { age: getSummary(current).get('healthAge') }
})(AgeDisplayContainer)

const SurveyLayout = ({ viewReport, newEntry, className, summary }) => {
  const electron = isElectron()

  // let desktopAgeDisplay = null
  // if (summary.healthAge) {
  //   desktopAgeDisplay = (
  //     <DesktopAgeDisplay>
  //       {t(`Health Age:`)} <strong>{summary.healthAge}</strong>
  //     </DesktopAgeDisplay>
  //   )
  // }

  return (
    <div className={className}>
      <SurveyView />

      <ButtonRow bottom>
        <BoundAgeDisplay />

        {!electron && (
          <ButtonGroup>
            <Button onClick={viewReport}>{t(`Show Report`)}</Button>
            <Button last onClick={newEntry}>
              {t(`New Entry`)}
            </Button>
          </ButtonGroup>
        )}
      </ButtonRow>

      {/* {electron && desktopAgeDisplay} */}
    </div>
  )
}

const eventMap = {
  viewReport,
  newEntry,
}

export default connect(
  null,
  eventMap,
)(SurveyLayout)
