import React from 'react'
import { t } from '../../lib/i18n'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-around;
`

const Button = styled.label`
  font-size: 30px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.61);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: ${(p) => p.active && 400};
  color: ${(p) => p.active && 'white'};
  margin-left: ${(p) => p.last && '0.5rem'};
`

const RadioButton = styled.input`
  margin-right: 4px;
`

const GenderButton = ({ children, onSelect, active, last }) => {
  return (
    <Button active={active} last={last}>
      <RadioButton
        type="radio"
        name="genderButton"
        onChange={onSelect}
        checked={active}
      />
      {children}
    </Button>
  )
}

const GenderButtons = ({ gender, onChangeGender, ...props }) => {
  return (
    <Container {...props}>
      <GenderButton
        active={gender === 'male'}
        onSelect={onChangeGender.bind(null, 'male')}
      >
        {t(`Male`)}
      </GenderButton>
      <GenderButton
        last
        active={gender === 'female'}
        onSelect={onChangeGender.bind(null, 'female')}
      >
        {t(`Female`)}
      </GenderButton>
    </Container>
  )
}

export default GenderButtons
