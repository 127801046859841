export const weakCache = (fn) => {
  const cache = new WeakMap()

  return (key, ...args) => {
    let result = cache.get(key)

    if (result) {
      return result
    }

    result = fn(key, ...args)
    cache.set(key, result)
    return result
  }
}
