import React from 'react'
import styled from 'styled-components'
import autoBind from 'auto-bind'
import moment from 'moment'

import { getJson } from '../lib/util'

const StatusCode = styled.td`
  color: ${(p) => (p.status === 'accept' ? 'green' : 'red')};
  ${'' /* font-weight: bold; */};
`

class ActivationLog extends React.Component {
  constructor() {
    super()
    autoBind(this)

    this.state = {
      log: null,
    }
  }
  componentWillMount() {
    getJson(`/admin/activationLog/${this.props.licenceKey}`)
      .then((log) => this.setState({ log }))
      .catch((e) => {
        this.setState({ log: 'Error fetching activation logs! ' + e })
        console.log(e)
      })
  }
  render() {
    if (!this.state.log) {
      return <p>Loading activation log...</p>
    } else if (typeof this.state.log === 'string') {
      return <p>{this.state.log}</p>
    } else if (this.state.log.length === 0) {
      return <p>No log entries to display.</p>
    } else {
      const logEntries = this.state.log.map((entry) => {
        entry = JSON.parse(entry)

        return (
          <tr key={entry.timestamp}>
            <td>{moment(entry.timestamp).format('HH:mm D/M/YY')}</td>
            <StatusCode status={entry.status}>{entry.status}</StatusCode>
            <td>{entry.reason}</td>
            <td>{entry.sessionSlug}</td>
            <td>
              <a
                href={`http://ipinfo.io/` + entry.ip}
                target="_blank"
                rel="noopener noreferrer"
              >
                {entry.ip}
              </a>{' '}
              ({entry.country})
            </td>
            <td>{entry.agentString}</td>
          </tr>
        )
      })

      return (
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Status</th>
              <th>Reason</th>
              <th>Session ID</th>
              <th>IP Address</th>
              <th>Browser / OS / Device</th>
            </tr>
          </thead>
          <tbody>{logEntries}</tbody>
        </table>
      )
    }
  }
}

export default ActivationLog
