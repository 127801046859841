import React from 'react'
import styled from 'styled-components'
import autoBind from 'auto-bind'
import { connect } from 'react-redux'
import isElectron from 'is-electron'
import NotificationSystem from 'react-notification-system'

import { doHeartbeat } from '../lib/socket'
import Switch from '../elements/Switch'
import { offlineMode, connectivity, licenceKey } from '../model/selectors'
import { offlineEnable, offlineDisable, resetState } from '../model/actions'
import { desktopUrl, buyMoreUrl } from '../lib/defines'

import { t } from '../lib/i18n.js'
// import { fullUrl } from '../lib/util'

import caret from '../assets/caret-down.svg'

const KeyC = styled.div`
  height: 100%;
  display: flex;
  padding: 0 10px;
  margin-left: 5px;
  align-items: center;
  cursor: pointer;
  background: ${(p) =>
    p.isOpen ? 'rgba(255, 255, 255, 0.18)' : 'rgba(0,0,0,0.20)'};
  &:hover {
    background: rgba(255, 255, 255, 0.18);
  }
  & img {
    opacity: ${(p) => (p.isOpen ? '1' : '0.5')};
    transform: ${(p) => (p.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    margin-left: 5px;
    height: 20px;
    transition: transform 0.1s linear;
  }
  &:hover img {
    opacity: 1;
  }
`
const KeyCol = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  user-select: none;
`
const Key = styled.code`
  padding: 2px 4px;
  border-radius: 2px;
  background: white;
  color: red;
`

const Container = styled.div`
  position: relative;
  height: 100%;
`

const Dropdown = styled.div`
  position: absolute;
  width: 350px;
  background: white;
  top: 75px;
  left: 5px;
  color: black;
  padding: 15px;
  padding-top: 10px;
  box-shadow: 2px 1px 7px 0px rgba(0, 0, 0, 0.15);
  display: ${(p) => (p.isOpen ? 'flex' : 'none')};
  flex-direction: column;

  @media (min-width: 960px) {
    top: 50px;
  }
`

const SectionHeader = styled.h2`
  font-weight: 400;
  font-size: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
`
const P = styled.div`
  font-size: ${(p) => (p.note ? '15px' : '18px')};
  ${(p) => p.note && 'color: #4c4c4c;'} margin-bottom: 5px;
  margin-top: 1em;
`
const ActionsList = styled.div`
  list-style: none;
  margin-top: 0;
  font-size: 17px;
  margin-bottom: 17px;
  padding-left: 40px;
`
const ActionListHeader = styled.span``
const Link = styled.a`
  color: #0070c9;
`
const MOBILE_BRK = '960px'
const KeyLabel = styled.div`
  margin-bottom: 5px;
  @media (min-width: ${MOBILE_BRK}) {
    margin-bottom: initial;
  }
`

const advancedLocalization = (string) => {
  string = string.replace(/\*\*(.+)\*\*/, '<strong>$1</strong>')
  string = string.replace(
    /\[\[(.+)\]\]/,
    `<a href="${desktopUrl}" style="color: #0070c9;" target="_blank">$1</a>`,
  )
  return <span dangerouslySetInnerHTML={{ __html: string }} />
}

const isLegacyWeb = !String(document.location).includes("app.discoverhealthage.com") && !String(document.location).includes("localhost")

const OfflineSection = ({ tip, switchState, switchChange }) => {
  if (!switchState && isLegacyWeb) {
    return null
  }

  return (
    <div>
      <SectionHeader>
        {t(`Offline Mode`)}{' '}
        <Switch on={switchState} change={switchChange} tip={tip} />
      </SectionHeader>
      <P>
        {t(
          `Offline mode saves this website in your browser so you can use it offline.`,
        )}
      </P>

      <P note>
        {t(
          `**Note:** The saved app expires 30 days after it was last loaded with internet available.  If you need long-term offline access please use our [[desktop app]].`,
          advancedLocalization,
        )}
      </P>
    </div>
  )
}

class ActivationInfo extends React.Component {
  constructor() {
    super()
    autoBind(this)

    this.state = {
      dropdownOpen: false,
    }

    this.notificationSys = null
  }
  toggle() {
    console.log(this.state.dropdownOpen)
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }
  changeMode(newMode) {
    const { offlineEnable, offlineDisable, online } = this.props
    if (online) {
      let revert

      if (newMode) {
        revert = offlineDisable
        offlineEnable()
      } else {
        revert = offlineEnable
        offlineDisable()
      }

      // immediately send the heartbeat
      doHeartbeat()
        .then((response) => {
          if (response.action === 'logout') {
            this.notificationSys.addNotification({
              title: t(`Permission Denied`),
              message: t(
                `This license key has been used to activate another computer.  Please log out.`,
              ),
              level: 'warning',
              position: 'bl',
              autoDismiss: 5,
            })
            revert()
          }
        })
        .catch((e) => {
          console.log(e)
          this.notificationSys.addNotification({
            title: t(`Error Contacting Server`),
            message: t(
              `Please contact support using the chat button to the right.`,
            ),
            level: 'error',
            position: 'bl',
            autoDismiss: 5,
          })
          revert()
        })
    }
  }
  logOut(e) {
    e.preventDefault()

    // report to intercom
    window.Intercom('trackEvent', 'erase-data-and-log-out')

    doHeartbeat('online')
      .then((r) => {
        this.props.resetState()
      })
      .catch((e) => {
        this.notificationSys.addNotification({
          title: t(`Error Contacting Server`),
          message: t(
            `An internet connection is required to allow the license key to be used on another computer.`,
          ),
          level: 'error',
          position: 'bl',
          autoDismiss: 5,
        })
      })
  }
  render() {
    const { mode, online, licenceKey, className } = this.props

    const offlineMsg = online
      ? undefined
      : t(`Connect to internet before disabling offline mode.`)

    return (
      <Container className={className}>
        <KeyC onClick={this.toggle} isOpen={this.state.dropdownOpen}>
          <KeyCol>
            <KeyLabel>{t(`Activated with:`)}</KeyLabel>
            <Key>{licenceKey || 'unknown'}</Key>
          </KeyCol>
          <img src={caret} alt="" />
        </KeyC>
        <Dropdown isOpen={this.state.dropdownOpen} className="dropdown">
          {!isElectron() || [
            <OfflineSection
              key="offlineSection"
              tip={offlineMsg}
              switchState={mode}
              switchChange={this.changeMode}
            />,
            <SectionHeader key="sectionHeader">{t(`Actions`)}</SectionHeader>,
          ]}
          <P>
            <ActionListHeader>{t(`My Account`)}</ActionListHeader>
            <ActionsList>
              {/* <li><Link href={fullUrl('/app/emailReminder?key=' + licenceKey)} target='_blank'>Email me all my keys</Link></li> */}
              <li>
                <Link href={buyMoreUrl} target="_blank">
                  {t(`Buy more keys`)}
                </Link>
              </li>
            </ActionsList>
            <ActionListHeader>{t(`This Installation`)}</ActionListHeader>
            <ActionsList>
              <li>
                <Link href="#/" onClick={this.logOut}>
                  {t(`Log out and erase data`)}
                </Link>
              </li>
            </ActionsList>
          </P>
        </Dropdown>

        <NotificationSystem ref={(ns) => (this.notificationSys = ns)} />
      </Container>
    )
  }
}

const mapState = (state) => {
  return {
    mode: offlineMode(state),
    online: connectivity(state),
    licenceKey: licenceKey(state),
  }
}
const eventMap = {
  offlineEnable,
  offlineDisable,
  resetState,
}

export default connect(
  mapState,
  eventMap,
)(ActivationInfo)
