import React from 'react'
import { css } from 'styled-components'
import styled from 'styled-components'
import { Focusable } from 'react-event-as-prop'

const Input = styled.input`
  background: inherit;
  border: none;
  font-weight: 400;
  font-size: 23px;
  margin: 0 6px;
  color: white;
  background: rgba(255, 255, 255, 0.29);
  border-radius: 4px;
  padding-left: 3px;
  ${(p) => p.large && 'flex-grow: 1;'} flex: auto;

  width: ${(p) => (p.large ? '20ch' : '7ch')};
`

const FieldContainer = styled.label`
  font-size: 24px;
  margin: 5px;
  display: flex;
  align-items: center;
  ${(p) => p.large && 'flex: auto;'} ${(p) =>
    p.large
      ? css`
          margin: 0;
          font-size: 25px;
          font-weight: 500;
        `
      : css`
          @media (max-width: 610px) {
            flex-direction: column;
          }
        `} margin-bottom: 13px;
`
const Label = styled.label``

const mkTip = (msg) => {
  return {
    'data-balloon': msg,
    'data-balloon-pos': 'up',
    'data-balloon-visible': true,
  }
}

class Field extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.value === this.props.value &&
      nextProps.focued === this.props.focused
    ) {
      return false
    } else {
      return true
    }
  }
  render() {
    const {
      large,
      value,
      valid,
      focused,
      className,
      onChange,
      invalidMsg,
      friendlyMsg,
      label,
      placeholder,
      hideInvalid,
      ...events
    } = this.props

    let tooltipParams = {}

    if (focused && !valid && !hideInvalid) {
      // invalid and has focus
      tooltipParams = mkTip(friendlyMsg)
    } else if (value && !focused && !valid && !hideInvalid) {
      // invalid and lost focus
      tooltipParams = mkTip(invalidMsg)
    }

    return (
      <FieldContainer large={large} {...tooltipParams}>
        <Label>{label}</Label>
        <Input
          className={className}
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          large={large}
          {...events}
        />
      </FieldContainer>
    )
  }
}

export default Focusable(Field)
