import { valueOf, isSubPar, getQuestions, getReportValue } from './qdb'
import { lookupAge } from './agedb'
import { bmiFromEntry } from './bmi'
import { weakCache } from './weakCache'

const getScore = (selections) => {
  // calculate the total score
  let score = 0
  selections.forEach((s, i) => {
    if (s) {
      score += valueOf(i, s)
    }
  })
  return score
}

// get the health age based on the survey selections
export const getHealthAge = (selections, realAge) => {
  // bail if the questionaire isn't filled out yet
  if (
    selections.includes(undefined) ||
    selections.includes(null) ||
    selections.isEmpty()
  ) {
    return 0
  }

  const score = getScore(selections)

  // lookup the health age
  return lookupAge(realAge, score)
}

export const getRecommendations = weakCache((survey) => {
  const iqdb = getQuestions()

  // for each of the questions
  const recommendations = iqdb.map((q, qid) => {
    // get the recommendations
    return (
      q
        .get('rcmd')
        // pre-compute whether it is subpar or not
        .set('subPar', isSubPar(qid, survey.get(qid)))
        .set('reportValue', getReportValue(qid, survey.get(qid)))
    )
  })

  console.log(recommendations.toJS())

  return (bmi) => {
    const weightRcmd = recommendations.get(9)

    return recommendations
      .setIn([9, 'subtitle'], weightRcmd.get('subtitle')({ bmi }))
      .setIn([9, 'textSubtitle'], weightRcmd.get('textSubtitle')({ bmi }))
  }
})

export const getSummary = weakCache((entry) => {
  // general info (name, age, weight, height, etc)
  const meta = entry.get('meta')

  // health age and potential health age
  let healthAge = 0
  let potentialAge = 0
  let score = 0
  if (meta.get('age')) {
    healthAge = getHealthAge(entry.get('survey'), Number(meta.get('age'))) || 0
    if (healthAge) {
      score = getScore(entry.get('survey'))
    }
    potentialAge = lookupAge(Number(meta.get('age')), 12) || 0
  }

  let summary = meta
    .set('healthAge', healthAge)
    .set('potentialAge', potentialAge)
    .set('score', score)
    .set(
      'recommendations',
      getRecommendations(entry.get('survey'))(bmiFromEntry(entry)),
    )

  // normalize age so invalid strings can't mess it up
  const age = Number(summary.get('age'))
  summary.set('age', age >= 0 ? age : 0)

  return summary
})
