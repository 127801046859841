import { decodeParam, params } from '../lib/query'
import moment from 'moment'
// import { createSelector } from 'reselect'
import { atou } from '../lib/utoa'
import { fromJS } from 'immutable'

// unwrap the undo history and to some sanity checks
const process = (state) => {
  if (!state) {
    throw new Error('Undefined state passed to the selector')
  }
  return state.present
}

const processEntries = (s) => process(s).entries
const processView = (s) => process(s).view
const processCustomization = (s) => process(s).customization

// structure
export function sidebarVisible(s) {
  return processView(s).get('sidebarVisible')
}
export function passedWelcome(s) {
  const printViewOnly = params.view === 'print'
  return printViewOnly || processView(s).get('passedWelcomeScreen')
}
export function currentView(s) {
  return processView(s).get('mainView')
}
export function connectivity(s) {
  return processView(s).get('online')
}
export function offlineOpen(s) {
  return processView(s).get('offlineOpen')
}
export function extendOfflineStatus(s) {
  return processView(s).get('extendOfflineStatus')
}
export function offlineUntil(s) {
  const since = processView(s).get('offlineSince')
  return since && moment(since).add(30, 'days')
}
export function offlineMode(s) {
  return processView(s).get('offlineMode')
}
export function isAdmin(s) {
  return processView(s).get('userLevel') === 'admin'
}
export function userLevel(s) {
  return processView(s).get('userLevel')
}
export function licenceKey(s) {
  return processView(s).get('licenceKey')
}
export function latestVersion(s) {
  return processView(s).get('latestVersion')
}
export function search(s) {
  return processView(s).get('entriesSearch')
}

// entries
export function entries(s) {
  return processEntries(s).get('entries')
}
export function currentEntryId(s) {
  return processEntries(s).get('currentEntry')
}
export function currentEntry(s) {
  const id = currentEntryId(s)
  const entryOverride = params.entry && fromJS(JSON.parse(atou(params.entry)))
  return entryOverride || processEntries(s).getIn(['entries', id])
}
export function meta(s) {
  return currentEntry(s).get('meta')
}
export function survey(s) {
  return currentEntry(s).get('survey')
}
export const entryById = (id, state) => {
  return entries(state).get(id)
}

// customizations
export function footerText(s) {
  return decodeParam('footer') || processCustomization(s).get('footer')
}
