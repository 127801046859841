import isElectron from 'is-electron'

import { store } from '../model/store'
import { serverCheckIn, logout } from '../model/actions'
import {
  offlineMode,
  connectivity,
  passedWelcome,
  licenceKey,
} from '../model/selectors'
import { getJson } from './util'
import { getLockedLocale } from './specialCases'

// generate a registration response
const getMode = () => {
  if (offlineMode(store.getState())) {
    return 'offline'
  } else if (isElectron()) {
    return 'standalone'
  } else {
    return 'online'
  }
}

const noServer = () => {
  return getLockedLocale(licenceKey(store.getState()))
}

export const pause = (time) =>
  new Promise((resolve) => {
    setTimeout(resolve, time)
  })

export const doHeartbeat = async (mode) => {
  if (noServer()) {
    return
  }

  mode = mode || getMode()
  return getJson('/app/heartbeat?mode=' + mode)
}

// client-requested registration (used in the saga when we go into offline mode)
const register = async () => {
  if (noServer()) {
    return
  }

  await pause(2000)

  for (;;) {
    const state = store.getState()

    if (!connectivity(state)) {
      console.log('skipping heartbeat because we are offline')
      await pause(2000)
      continue
    }

    if (!passedWelcome(state)) {
      console.log(
        'skipping heartbeat because we are still on the welcome screen',
      )
      await pause(2000)
      continue
    }

    try {
      const response = await getJson('/app/heartbeat?mode=' + getMode())

      if (response.status === 'success') {
        store.dispatch(
          serverCheckIn(
            response.userLevel || 'standard',
            response.latestVersion,
          ),
        )
      } else {
        console.log('heartbeat denied.  Logging out')
        store.dispatch(logout())
      }
    } catch (e) {
      console.log('error contacting server', e)
      // bump the offline checker so it checks as well
      window.Offline.check()
    }

    await pause(60000)
  }
}

// start the registration loop
register()
