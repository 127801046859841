import React, { useState } from 'react'
import styled from 'styled-components'
import { postJson } from '../lib/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div`
  background: #616161;
  height: 50px;
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 0 100px;
  position: fixed;
  bottom: ${(p) => (p.saved ? '-50px' : '0')};
  transition: bottom 0.1s linear;
  left: 0;
`

const SearchLabel = styled.div`
  color: hsla(0, 0%, 100%, 0.87);
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`

const CreateButton = styled.button`
  background: rgba(255, 255, 255, 0.29);
  margin-left: 2ch;
  border-radius: 7px;
  border: none;
  font-size: 18px;
  padding: 4px 11px;
  color: white;
  cursor: pointer;
`

const SaveIcon = styled(FontAwesomeIcon)`
  margin-right: 1ch;
`

const ErrMsg = styled.div`
  margin-left: 1ch;
  color: hsla(0, 0%, 100%, 0.87);
  font-size: 16px;
`

const SaveBar = ({ saved, info, onSave }) => {
  const [saving, setSaving] = useState(false)
  const [err, setErr] = useState('')

  const saveNow = async () => {
    try {
      setSaving(true)
      setErr('')
      await postJson('/admin/keys/', info)
      onSave(info)
    } catch (e) {
      setErr('There was an error saving the current document.')
    } finally {
      setSaving(false)
    }
  }

  return (
    <Container saved={saved}>
      <SearchLabel>You have unsaved changes.</SearchLabel>
      <CreateButton onClick={saveNow}>
        {saving && <SaveIcon icon={faCircleNotch} spin />}
        Save Now
      </CreateButton>
      <ErrMsg>{err}</ErrMsg>
    </Container>
  )
}

export default SaveBar
