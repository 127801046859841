import val from 'aproba-browser'
import ReactDOM from 'react-dom'

import { params } from './query'
import { store } from '../model/store'
import { licenceKey } from '../model/selectors'

export function round(n, d) {
  val('NN', [n, d])
  return Number(n.toFixed(d))
}

export function elementToString(el) {
  const div = document.createElement('div')
  ReactDOM.render(el, div)
  return div.innerHTML
}

export function isReactElement(obj) {
  if (!obj) return false
  if (typeof obj['$$typeof'] !== 'symbol') return false

  return obj['$$typeof'].toString() === 'Symbol(react.element)'
}

export function firstName(name) {
  return name.split(' ')[0]
}

// const isDev = params.dev === 'yes'
// const fallbackBaseurl = isDev
//   ? 'http://localhost:9998'
//   : 'https://discoverhealthage.com/api'
const fallbackBaseurl = 'https://discoverhealthage.com/api'
export const BASE_URL = params.baseurl || fallbackBaseurl

export const fullUrl = (url) => {
  return BASE_URL + url
}

export const getJson = async (url) => {
  url = fullUrl(url)

  return await window
    .fetch(url, { credentials: 'include', cache: 'no-store' })
    .then((r) => r.json())
}
export const postJson = (url, data) => {
  url = fullUrl(url)

  if (typeof data !== 'string') {
    data = JSON.stringify(data, null, 2)
  }
  return window
    .fetch(url, {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-store',
      method: 'POST',
      body: data,
    })
    .then((r) => r.json())
    .then((r) => {
      if (r.redirect) {
        window.location = r.redirect
      } else {
        return r
      }
    })
}

export const updateIntercom = (info) => {
  const state = store.getState()

  const update = {
    user_id: licenceKey(state),
    ...info,
  }

  console.log('updating intercom', update)
  window.Intercom('update', update)
}
