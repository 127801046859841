import '../assets/vex.css'
import '../assets/vex-theme-default.css'

import vex from 'vex-js'
import vDialog from 'vex-dialog'
vex.registerPlugin(vDialog)

vex.defaultOptions.className = 'vex-theme-default'

const vexMethod = name => {
  return (msg, opts = {}) => {
    return new Promise(resolve => {
      vex.dialog[name]({
        message: msg,
        ...opts,
        callback: resolve
      })
    })
  }
}

const alert = vexMethod('alert')
const prompt = vexMethod('prompt')
const open = vexMethod('open')

export default vex
export { prompt, alert, open }
