import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import isElectron from 'is-electron'

import { getJson } from '../lib/util'
import { params } from '../lib/query'
import { finishWelcome } from '../model/actions'
import { Container, Loading } from './elements'
import { getLockedLocale } from '../lib/specialCases'

import Forgot from './Forgot'

import goImg from '../assets/go.svg'

const Prompt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 11px;

  & > div:nth-child(n + 1) {
    opacity: 1;
    font-size: 25px;
  }
  & > div:nth-child(n + 2) {
    opacity: 0.9;
    font-size: 23px;
  }
  & > div:nth-child(n + 3) {
    opacity: 0.8;
    font-size: 21px;
  }
  & > div:nth-child(n + 4) {
    opacity: 0.7;
    font-size: 19px;
  }
`

const Form = styled.form`
  display: flex;
`

const ResetLink = styled(Link)`
  align-self: flex-end;
  color: #5d5d5d;
  margin-top: 11px;
  font-size: 18px;
`

const Error = styled.div`
  color: red;
  font-size: 17px;
  margin-bottom: 4px;
`

const Alert = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  margin: 20px;
  text-align: center;

  a {
    color: #533f03;
  }
`

const Input = styled.input`
  ${'' /* override Safari's default styles */} border-radius: 0;
  -webkit-appearance: none;

  box-shadow: none;
  font-size: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => (p.invalid ? 'red' : 'darkgray')};
  flex-grow: 1;
`

const Submit = styled.button`
  background: white;
  border: 1px solid darkgrey;
  border-left: none;
  background: url(${goImg}) center no-repeat;
  width: 32px;
  cursor: pointer;
`

const DownloadLink = styled.a`
  position: fixed;
  top: 10px;
  right: 20px;
  color: gray;
  font-weight: 100;
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
  }
`

const LoginDisplay = ({
  isInvalid,
  currentKey,
  onChange,
  submitClick,
  error,
}) => {
  const styles = {
    submit: {
      backgroundColor: 'white',
      border: '1px solid darkgrey',
      borderLeft: 'none',
      background: ``,
      width: '32px',
      cursor: 'pointer',
    },
  }

  // redirect to the new site, if they're logged out
  let moveMsg = null
  const loc = String(document.location)
  if (!isElectron() && !loc.includes("app.discoverhealthage.com")) {
    moveMsg = (
      <Alert>
        The web version has moved to <a href="https://app.discoverhealthage.com">app.discoverhealthage.com</a>.<br />
        Please login there to ensure you get the full experience.
      </Alert>
    )
  }

  return (

    <Container alert={moveMsg}>
      {!isElectron() && (
        <DownloadLink href="https://api.discoverhealthage.com/dl/desktop" target="_blank">
          Desktop Version
          </DownloadLink>
      )}

      <Prompt>
        <div>Enter your license key</div>
        <div>Ingrese su clave de licencia</div>
        <div>Digite sua chave de licença</div>
        <div>Geben Sie Ihre Lizenzschlüssel</div>
        <div>输入您的许可证密钥</div>
      </Prompt>

      {error && <Error>{error}</Error>}

      <Form onSubmit={submitClick}>
        <Input
          type="text"
          autoFocus
          invalid={isInvalid}
          value={currentKey}
          onChange={onChange}
        />
        <Submit type="submit" style={styles.submit} onClick={submitClick} />
      </Form>

      <ResetLink to="/forgot">I don't have a key</ResetLink>

      {/* <p>The Discover Health Age login server is down for maintenance.</p>
      <p>We'll be back ASAP!</p> */}
    </Container>
  )
}

const sanitizeKey = (key) => {
  // default to empty string
  key = key || ''

  // convert to lower case
  key = key.toLowerCase()

  // search for the result based on the pattern
  const result = /[\w-.]{4,}/.exec(key)

  // return null if no match, otherwise return the sanitized key
  return result && result[0]
}

class LoginWindow extends React.Component {
  constructor() {
    super()

    this.state = {
      key: params.key || '',
      fetching: false,
      error: null,
      invalidKey: false,
    }
  }
  notifyInvalid = (e) => {
    this.setState({ invalidKey: true })
  }
  updateKey = (e) => {
    const newKey = sanitizeKey(e.target.value) || e.target.value
    this.setState({ key: newKey, invalidKey: false })
  }
  handleSubmit = (e) => {
    e.preventDefault()

    const forcedLocale = getLockedLocale(this.state.key)
    if (forcedLocale) {
      // config('locale', forcedLocale)
      window.history.pushState(null, '', document.location.href.split('?')[0])
      this.props.done(this.state.key)
    }
    this.setState({ fetching: true }, () => {
      getJson(`/app/api/init?key=${this.state.key}`)
        .then((r) => {
          if (r.status === 'success') {
            this.setState(
              {
                fetching: false,
                error: '',
              },
              () => {
                window.history.pushState(
                  null,
                  '',
                  document.location.href.split('?')[0],
                )
                this.props.done(this.state.key, r.location)
              },
            )
          } else if (r.error) {
            this.setState({
              fetching: false,
              error: r.error,
            })
          } else {
            this.setState({
              fetching: false,
              error: 'Unknown error.',
            })
          }
        })
        .catch((e) => {
          console.log(e)
          this.setState({
            fetching: false,
            error: 'Error contacting server.  Are you online?',
          })
        })
    })
  }
  render() {
    if (this.state.fetching) {
      return <Loading msg="Activating your licence..." />
    }

    return (
      <LoginDisplay
        isInvalid={this.state.invalidKey}
        currentKey={this.state.key}
        onChange={this.updateKey}
        submitClick={this.handleSubmit}
        loading={this.state.loading}
        error={this.state.error}
      />
    )
  }
}

const eventMap = {
  done: finishWelcome,
}

export default connect(
  null,
  eventMap,
)(LoginWindow)
export { Forgot }
