import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 32px;
  width: 32px;
  background: ${(p) => p.bg};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LetterAvatar = ({ letters, className, score }) => {
  // yellow
  let bg = '#ccc469'

  if (!score) {
    // gray
    bg = '#a6a6a6'
  } else if (score > 8) {
    // green
    bg = '#7bcc69'
  } else if (score < 6) {
    // red
    bg = '#cc6b6b'
  }

  return (
    <Container bg={bg} className={className}>
      {letters ? letters.join('') : '?'}
    </Container>
  )
}

export default LetterAvatar
