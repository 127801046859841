import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import isElectron from 'is-electron'
// import cmpVer from 'semver-compare'
import autoBind from 'auto-bind'
import cs from 'classnames'
import NotificationSystem from 'react-notification-system'

import { doHeartbeat } from '../lib/socket'
import { t } from '../lib/i18n'
import {
  toggleSidebar,
  viewSurvey,
  viewReport,
  resetState,
} from '../model/actions'
import {
  sidebarVisible,
  isAdmin,
  latestVersion,
  currentView,
  currentEntry,
} from '../model/selectors'
import { config } from '../model/globalConfig'
import { print } from '../lib/print.js'
import { getMailto } from '../lib/emailReport'
import { getSummary } from '../lib/healthAge'
import { getParams } from '../lib/query'
import languages from '../lib/languages.json'
// import { desktopUrl } from '../lib/defines'
// import { version } from '../lib/package.json'

const HeaderContainer = styled.div`
  height: 40px;
  flex: none;
  border-bottom: 1px solid rgba(170, 169, 170, 1);
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0 8px;
`

const HeaderArea = styled.div`
  flex: 1 1 0;
  display: flex;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }

  &:last-child {
    justify-content: flex-end;
  }
`

const DropdownItem = ({ active, children, ...props }) => {
  return (
    <a
      style={{ cursor: 'pointer', color: active ? 'white' : '#212529' }}
      className={cs('dropdown-item', { active })}
      {...props}
    >
      {children}
    </a>
  )
}

// const languages = [
//   { code: 'en', name: 'English' },
//   { code: 'es', name: 'Español (Spanish)' },
//   { code: 'pt', name: 'Português (Portuguese)' },
//   { code: 'de', name: 'Deutsch (German)' },
//   { code: 'zh', name: '官话 (Mandarin)' },
//   { code: 'ar', name: 'العَرَبِيَّ (Arabic)ة' },
//   { code: 'pa', name: 'Papiamentu' },
//   { code: 'vi', name: 'Tiếng Việt (Vietnamese)' },
//   { code: 'fr', name: 'Français (French)' },
//   { code: 'mg', name: 'Malagasy' },
//   { code: 'ru', name: 'русский язык (Russian)' },
//   { code: 'ko', name: '한국어 (Korean)' },
//   { code: 'hi', name: 'हिंदी (Hindi)' },
//   { code: 'ta', name: 'தமிழ் (Tamil)' },
// ]

class Header extends React.Component {
  constructor() {
    super()
    autoBind(this)

    this.state = {
      dropdownValue: 'title',
    }

    this.notificationSys = null
  }
  componentDidMount() {
    if (getParams().logOut) {
      console.log('params say we should log out =>', window.location)
      this.logOut()
    }
  }
  logOut() {
    // report to intercom
    window.Intercom('trackEvent', 'erase-data-and-log-out')

    doHeartbeat('online')
      .then((r) => {
        this.props.resetState()
        localStorage.setItem('recover', 'no')
      })
      .catch((e) => {
        try {
          this.notificationSys.addNotification({
            title: t(`Error Contacting Server`),
            message: t(
              `An internet connection is required to allow the license key to be used on another computer.`,
            ),
            level: 'error',
            position: 'bl',
            autoDismiss: 5,
          })
        } catch (e) {
          // it will try to fire a second time on the login screen.  Ignore it.
        }
      })
  }
  selectDropdownOption(e) {
    e.persist()
    this.setState({ dropdownValue: 'dummy' }, () => {
      window.open(e.target.value)
      this.setState({ dropdownValue: 'title' })
    })
  }
  render() {
    const {
      toggleSidebar,
      sidebarVisible,
      currentView,
      viewReport,
      viewSurvey,
      summary,
    } = this.props

    const changeLanguage = (newValue) => () => {
      if (isElectron()) {
        const { ipcRenderer } = window.require('electron')
        ipcRenderer.send('change-locale', { locale: newValue })
      } else {
        config('locale', newValue)
        window.location.reload()
      }
    }

    const locale = config('locale')

    // const updateNeeded = isElectron() && (cmpVer(latestVersion, version) === 1)
    // const updateNeeded = false

    return (
      <HeaderContainer className="toolbar toolbar-header">
        {/* <h1 className="title">Header with actions</h1> */}
        <HeaderContent>
          <HeaderArea>
            <button
              className={cs('btn btn-default', { active: sidebarVisible })}
              onClick={toggleSidebar}
            >
              <span className="icon icon-doc-text icon-text" />
              {t(`Entries`)}
            </button>
          </HeaderArea>
          <HeaderArea>
            <div className="btn-group pull-right">
              <button
                className={cs('btn btn-default', {
                  active: currentView === 'survey',
                })}
                onClick={viewSurvey}
              >
                <span className="icon icon-pencil icon-text " />
                {t(`Edit Entry`)}
              </button>
              <button
                className={cs('btn btn-default', {
                  active: currentView === 'report',
                })}
                onClick={viewReport}
              >
                <span className="icon icon-newspaper icon-text" />
                {t(`View Report`)}
              </button>
            </div>
          </HeaderArea>
          <HeaderArea>
            <div className="btn-group pull-right dropdown">
              <button
                className="btn btn-default btn-dropdown"
                data-toggle="dropdown"
              >
                <span className="icon icon-print icon-text " />
                {t(`Print`)}
              </button>
              <a
                className="btn btn-default last-hint"
                href={getMailto(summary)}
                style={{
                  textDecoration: 'none',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                }}
              >
                <span className="icon icon-mail icon-text" />
                {t(`Email Report`)}
              </a>
              <div className="dropdown-menu">
                <DropdownItem onClick={print}>
                  {t(`Print Current Report`)}
                </DropdownItem>
                <div className="dropdown-divider" />
                <DropdownItem
                  target="_blank"
                  href={`/dl/questionnaire-${config('locale')}.pdf`}
                >
                  {t(`Print Blank Questionnaire`)}
                </DropdownItem>
                <DropdownItem
                  target="_blank"
                  href={`/dl/report-${config('locale')}.pdf`}
                >
                  {t(`Print Blank Report`)}
                </DropdownItem>
              </div>
            </div>

            <div className="dropdown">
              <button
                style={{ marginLeft: '1rem' }}
                className={cs('btn btn-default btn-dropdown')}
                data-toggle="dropdown"
              >
                <span className="icon icon-chat icon-text" />
                {t(`Language`)}
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {languages.map(([code, name]) => {
                  console.log(code, name)
                  return (
                    <DropdownItem
                      onClick={changeLanguage(code)}
                      active={locale === code}
                      key={code}
                    >
                      {name}
                    </DropdownItem>
                  )
                })}
              </div>
            </div>
          </HeaderArea>
        </HeaderContent>
        <NotificationSystem ref={(ns) => (this.notificationSys = ns)} />
      </HeaderContainer>
    )
  }
}

const mapState = (state) => {
  return {
    sidebarVisible: sidebarVisible(state),
    isAdmin: isAdmin(state),
    latestVersion: latestVersion(state),
    currentView: currentView(state),
    summary: getSummary(currentEntry(state)),
  }
}
const eventMap = {
  toggleSidebar,
  viewReport,
  viewSurvey,
  resetState,
}

export default connect(
  mapState,
  eventMap,
)(Header)
