// external libs
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'

// local libs
import { t } from '../lib/i18n'
import { meta } from '../model/selectors'
import { updateMeta } from '../model/actions'

import { InterestCheckbox, OtherCheckbox } from './elements/InterestCheckbox'
import LabeledField from './elements/LabeledField'

const Container = styled.div`
  background: white;
  display: flex;
  margin-top: 7px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  padding: 11px;
  flex-direction: column;
`

const Header = styled.h4`
  margin: 0;
  border-bottom: 2px solid lightgray;
  font-family: roboto slab;
  margin-bottom: 6px;
`

const CheckboxGrid = styled.div`
  column-count: 3;
  display: block;

  @media (max-width: 750px) {
    column-count: 2;
  }

  @media (max-width: 500px) {
    column-count: 1;
  }
`

const ContactInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const bindToMetaValue = connect(
  // mapState
  (state, { slug }) => {
    const entryMeta = meta(state)
    return {
      value: entryMeta.get(slug),
    }
  },
  // eventMap
  {
    update: updateMeta,
  },
)

const IntCh = bindToMetaValue(({ slug, label, value, update, keystroke }) => {
  return (
    <InterestCheckbox
      label={label}
      checked={!!value}
      onChange={(newValue) => update(slug, newValue)}
      keystroke={keystroke}
    />
  )
})

const BoundOtherCheckbox = bindToMetaValue(({ value, update }) => {
  return (
    <OtherCheckbox
      value={value}
      onChange={(newValue) => {
        update('other', newValue)
      }}
    />
  )
})

const BoundLabeledField = bindToMetaValue(
  ({ slug, value, update, caption }) => {
    return (
      <LabeledField
        caption={caption}
        value={value}
        onChange={(newValue) => update(slug, newValue)}
      />
    )
  },
)

const SmallField = styled(BoundLabeledField)`
  width: 25%;
`

const Footer = ({ entry, update, ksMap }) => {
  // generate an interest checkbox
  // (saves redundant props)
  const icb = (slug, caption) => {
    return <IntCh slug={slug} label={caption} keystroke={ksMap[slug]} />
  }

  return (
    <Container>
      <Header>{t(`Interests`)}</Header>

      <CheckboxGrid tabIndex="0">
        {icb('wm', t(`Weight Management`))}
        {icb('cooking', t(`Healthy Cooking`))}
        {icb('heart', t(`Heart Disease Prevention`))}
        {icb('diabetes', t(`Reversing Diabetes`))}
        {icb('exersise', t(`Fitness and Exercise`))}
        {icb('stress', t(`Stress Reduction`))}
        {icb('depression', t(`Depression Recovery`))}
        {icb('mind', t(`Improving Mental Performance`))}
        {icb('addictions', t(`Stop Smoking`))}
        {icb('remedies', t(`Natural Remedies`))}
        {icb('spiritual', t(`Spiritual Health`))}
        <BoundOtherCheckbox slug="other" />
      </CheckboxGrid>

      <ContactInfo>
        <BoundLabeledField caption={t(`Name`)} slug="name" />
        <BoundLabeledField caption={t(`Email`)} slug="email" />
        <BoundLabeledField caption={t(`Address`)} slug="address" />
        <SmallField caption={t(`Phone`)} slug="phone" />
        <SmallField caption={t(`Zip`)} slug="zip" />
      </ContactInfo>
    </Container>
  )
}

export default Footer
