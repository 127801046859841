import { isReactElement, firstName } from '../lib/util'

import { t } from './i18n'

export const getMailto = (summary) => {
  window.Intercom('trackEvent', 'emailed-a-report')

  // TODO: hi there needs to be translated
  // TODO: CSV translation may have problems

  const address = summary.get('email') || 'missing_address@example.com'
  const fullName = summary.get('name') || ''

  const name = firstName(fullName || '')
  const greeting = name ? t(`Hi ##NAME##,`, name) : t(`Hi there,`)

  const habitMessages = summary.get('recommendations').map((r) => {
    let subtitle = r.get('subtitle')
    if (isReactElement(subtitle)) {
      subtitle = r.get('textSubtitle')
    }

    let scoreWord
    switch (r.get('reportValue')) {
      case 0:
        scoreWord = t(`Poor`)
        break
      case 0.5:
        scoreWord = t(`Good`)
        break
      case 1:
        scoreWord = t(`Excellent!`)
        break
      default:
        scoreWord = t(`Error`)
    }

    return `${r.title}:
${subtitle}
${t(`Your Score:`)} ${scoreWord}

`
  })

  let message = `${greeting}

${t(`You recently requested a health age report via email:`)}

${t(`Your health age:`)} ${summary.get('healthAge')}
${t(
  `If you practiced all the health habits, your health age could be:`,
)} ${summary.get('potentialAge')}

${t(`The 12 health habits:`)}

`

  message += habitMessages.join('')

  //   if (config('locale') === 'en') {
  //     message = `${greeting}
  //
  // You recently requested a health age report via email.  Click this link to see your results:
  //
  // ${getPdfUrl()}
  //     `
  //   }

  message = window.encodeURIComponent(message)

  return `mailto:${address}?subject=Health%20Age%20Report&body=${message}`
}

export const sendEmail = (summary) => {
  const a = document.createElement('a')
  a.href = getMailto(summary)
  a.click()
}
