import { config } from '../model/globalConfig'
import { footerText, currentEntry } from '../model/selectors'
import { store } from '../model/store'
import { fullUrl } from './util'
import { utoa } from '../lib/utoa'

export const getPdfUrl = () => {
  const state = store.getState()
  const footer = footerText(state)
  const entry = currentEntry(state)

  const entryHash = utoa(JSON.stringify(entry.toJS()))
  const locale = config('locale')
  const footerHash = utoa(footer) || ''
  const logoHash = utoa(config('customLogo') || '')
  return fullUrl(
    `/get-report/https://discoverhealthage.com/?view=print&entry=${entryHash}&locale=${locale}&footer=${footerHash}&logo=${logoHash}`,
  )
}

export const print = () => {
  window.Intercom('trackEvent', 'printed-a-report')

  window.print()
}
