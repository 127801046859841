import React from 'react'
import { css } from 'styled-components'
import styled from 'styled-components'

import ActivationLog from './ActivationLog'
import { getJson } from '../lib/util'

const OuterContainer = styled.div`
  overflow: scroll;
  flex: auto;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 25px;
  padding-left: 50px;
  margin-bottom: 50px;
`

const Title = styled.h1`
  font-weight: 400;
  font-family: monospace;
  font-size: 40px;
`

const Section = styled.div``
const SecTitle = styled.h4``

const Table = styled.div`
  display: flex;
  border: 1px solid #cbcbcb;
  width: fit-content;
`

const cellStyles = css`
  padding: 0.5em 1em;
  border-bottom: 1px solid #cbcbcb;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  &:last-child {
    border-bottom-width: 0;
  }
  height: 35px;
`

const HeaderCol = styled.div`
  display: flex;
  flex-direction: column;
  background: #eee;

  & > div {
    ${cellStyles} justify-content: flex-end;
  }
`
const ValueCol = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    ${cellStyles} justify-content: flex-start;
    align-items: center;
  }
`

const Option = styled.span`
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
`

const Notes = styled.textarea``

const Reset = styled.button`
  margin-left: 10px;
  cursor: pointer;
`

const Delete = styled.button`
  margin-top: 25px;
  padding: 4px;
  cursor: pointer;
`

const OptionPicker = ({ value, options, tooltip, onSelect }) => {
  const opts = options.map((o, i) => {
    let tip = {}
    if (tooltip && tooltip[i]) {
      tip = {
        'data-balloon': tooltip[i],
        'data-balloon-pos': 'up',
        'data-balloon-length': 'medium',
      }
    }

    return (
      <Option key={o} onClick={() => onSelect(o)} {...tip}>
        <input readOnly type="radio" checked={o === value} /> {o}
      </Option>
    )
  })

  return <div>{opts}</div>
}

const KeyInfo = ({ info, onChange }) => {
  if (!info) {
    return null
  }

  const getUpdater = (dbkey) => (newValue) => {
    let newInfo = Object.assign({}, info, { [dbkey]: newValue })
    onChange(newInfo)
    // getJson(`/admin/update/${info.key}/${dbkey}?newValue=${newValue}`)
  }

  const notesUpdater = getUpdater('notes')
  const updateNotes = (e) => {
    notesUpdater(e.target.value)
  }

  const resetter = getUpdater('mode')
  const resetMode = () => resetter('online')

  const deleteKey = () => {
    const shouldContinue = window.confirm(
      `Are you sure you want to delete the license key "${
        info.key
      }"?  This action cannot be undone.`,
    )

    if (shouldContinue) {
      getJson(`/admin/delete/${info.key}`)
    }
  }

  return (
    <OuterContainer>
      <Container>
        <Title>
          {info.key}
          {info.key === window.localStorage.licenceKey && ' (me)'}
        </Title>
        <Section>
          <Table>
            <HeaderCol>
              <div>User Level</div>
              <div>Status</div>
              <div>Client Mode</div>
              <div>Last Seen</div>
            </HeaderCol>
            <ValueCol>
              <div>
                <OptionPicker
                  value={info.userLevel || 'standard'}
                  onSelect={getUpdater('userLevel')}
                  options={['standard', 'unlimited', 'admin']}
                  tooltip={[
                    'Only one device can be activated at a time.',
                    'Disable activation limits.',
                    'Same as unlimited, but also has access to the admin dashboard.',
                  ]}
                />
              </div>
              <div>
                <OptionPicker
                  value={info.status}
                  onSelect={getUpdater('status')}
                  options={['active', 'disabled']}
                  tooltip={[
                    'The key is enabled.',
                    'The key is disabled and cannot be used.  Any existing sessions will be terminated.',
                  ]}
                />
              </div>
              <div>
                {info.mode || 'unknown'}{' '}
                {!['online', undefined].includes(info.mode) && (
                  <Reset onClick={resetMode}>reset</Reset>
                )}
              </div>
              <div>{info.lastCheckInFriendly || 'never'}</div>
            </ValueCol>
          </Table>

          <Delete onClick={deleteKey}>Delete this license key</Delete>
        </Section>
        <Section>
          <SecTitle>Notes</SecTitle>
          <Notes
            rows="10"
            cols="50"
            value={info.notes || ''}
            onChange={updateNotes}
          />
        </Section>
        <Section>
          <SecTitle>Activation Log</SecTitle>

          {/* set the key prop to force react to discard the element when the key changes */}
          <ActivationLog key={info.key} licenceKey={info.key} />
        </Section>
      </Container>
    </OuterContainer>
  )
}

export default KeyInfo
