import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
// import isElectron from 'is-electron'
// import cmpVer from 'semver-compare'
import autoBind from 'auto-bind'

import { t } from '../lib/i18n'
import { toggleSidebar } from '../model/actions'
import {
  sidebarVisible,
  isAdmin,
  latestVersion,
  licenceKey,
} from '../model/selectors'
import { config } from '../model/globalConfig'
import ActivationInfo from './ActivationInfo'
import { getLockedLocale } from '../lib/specialCases'
// import { desktopUrl } from '../lib/defines'
// import { version } from '../lib/package.json'

import languages from '../lib/languages.json'

import apple from '../assets/apple.png'
import sidebarIcon from '../assets/entries.svg'

const MOBILE_BRK = '960px'

const Icon = styled.img`
  height: 100%;
  ${'' /* margin: 0 8px 0 8px; */} padding: 10px;
  width: 45px;
  background: transparent;
  transition: 0.2s background;
  cursor: pointer;
  background: ${(p) => p.active && '#4c4c4c'};
  box-sizing: border-box;

  @media (max-width: ${MOBILE_BRK}) {
    width: 65px;
    padding: 13px;
  }
`

const Container = styled.div`
  ${'' /* position: fixed;
  right: 0;
  top: 0;
  left: 0; */} display: flex;
  align-items: center;
  /* border-bottom: 1px solid lightgray; */
  font-size: 16px;
  background: hsla(0, 0%, 13%, 0.9);
  opacity: 1;
  height: 75px;
  padding: 0 0 0 10px;
  z-index: 1;
  color: white;
  box-shadow: 2px 1px 7px 0px rgba(0, 0, 0, 0.15);
  & > * {
    flex: none;
  }

  @media (min-width: ${MOBILE_BRK}) {
    height: 50px;
    font-size: 14px;
  }
`

const Logo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  border-left: none;
`

const Img = styled.img`
  height: 43px;
`

const Link = styled.a`
  color: white !important;
  text-decoration: none;
  padding: 0 12px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 17px;
  flex: none;

  &:hover {
    background: rgba(255, 255, 255, 0.18);
  }

  @media (min-width: ${MOBILE_BRK}) {
    font-size: 16px;
  }
`

const LogoLink = styled.a`
  @media (max-width: 800px) {
    display: none;
  }
`

const Select = styled.select`
  margin: 0 12px;
`

const DesktopOptions = styled.div`
  display: none;
  flex-shrink: 1;
  height: 100%;
  align-items: center;
  min-width: 0;

  @media (min-width: ${MOBILE_BRK}) {
    display: flex;
  }
`

const MobileOptions = styled.select`
  margin-left: 5px;
  @media (min-width: ${MOBILE_BRK}) {
    display: none;
  }
`

const StyledActivationInfo = styled(ActivationInfo)`
  margin-right: auto;
`

const ScrollingSection = styled.div`
  height: 100%;
  flex-shrink: 1;
  flex-grow: 0;
  min-width: 0;
  display: flex;
  align-items: center;
  overflow: auto;
  margin-left: auto;
`

class Header extends React.Component {
  constructor() {
    super()
    autoBind(this)

    this.state = {
      dropdownValue: 'title',
    }
  }
  selectDropdownOption(e) {
    e.persist()
    this.setState({ dropdownValue: 'dummy' }, () => {
      window.open(e.target.value)
      this.setState({ dropdownValue: 'title' })
    })
  }
  render() {
    const { toggleSidebar, sidebarVisible, isAdmin, licenceKey } = this.props

    const changeLanguage = (e) => {
      config('locale', e.target.value)
      window.location.reload()
    }

    const hideLangSwitcher = getLockedLocale(licenceKey)

    // const updateNeeded = isElectron() && (cmpVer(latestVersion, version) === 1)
    // const updateNeeded = false

    return (
      <Container className="header">
        <LogoLink
          href="https://www.healthexpobanners.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo className="applec">
            <Img src={apple} alt="" />
          </Logo>
        </LogoLink>

        <StyledActivationInfo />

        <ScrollingSection>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://manual.discoverhealthage.com"
          >
            {t(`User Manual`)}
          </Link>

          <DesktopOptions>
            {isAdmin && (
              <Link target="_blank" rel="noopener noreferrer" href="#/admin">
                Admin Dashboard
              </Link>
            )}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={`/dl/questionnaire-${config('locale')}.pdf`}
            >
              {t(`Print Questionnaire`)}
            </Link>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={`/dl/report-${config('locale')}.pdf`}
            >
              {t(`Print Blank Report`)}
            </Link>
          </DesktopOptions>

          <MobileOptions
            value={this.dropdownValue}
            onChange={this.selectDropdownOption}
          >
            <option value="title" disabled>
              {t(`Blank Printouts`)}
            </option>
            <option value={`/dl/questionnaire-${config('locale')}.pdf`}>
              {t(`Print Questionnaire`)}
            </option>
            <option value={`/dl/report-${config('locale')}.pdf`}>
              {t(`Print Blank Report`)}
            </option>
          </MobileOptions>

          {!hideLangSwitcher && (
            <Select onChange={changeLanguage} value={config('locale')}>
              {languages.map(([code, name]) => {
                return (
                  <option value={code} key="code">
                    {name}
                  </option>
                )
              })}
            </Select>
          )}
        </ScrollingSection>

        <Icon
          src={sidebarIcon}
          alt="toggle sidebar"
          onClick={toggleSidebar}
          active={sidebarVisible}
        />
      </Container>
    )
  }
}

const mapState = (state) => {
  return {
    sidebarVisible: sidebarVisible(state),
    isAdmin: isAdmin(state),
    latestVersion: latestVersion(state),
    licenceKey: licenceKey(state),
  }
}
const eventMap = {
  toggleSidebar,
}

export default connect(
  mapState,
  eventMap,
)(Header)
