import { combineReducers } from 'redux'
import undoable, { excludeAction } from 'redux-undo'
// import Raven from 'raven-js'

import { reduce as view } from './view'
import { reduce as entries } from './entries'
import { reduce as customization } from './customization'

import {
  FINISH_WELCOME,
  OFFLINE,
  ONLINE,
  OFFLINE_ENABLE,
  OFFLINE_DISABLE,
  SERVER_CHECKIN,
  SERVER_CHECKIN_FAILED,
  RESET_STATE,
  DO_MIGRATION,
} from '../actions'

const undoOpts = {
  filter: excludeAction([
    FINISH_WELCOME,
    OFFLINE,
    ONLINE,
    OFFLINE_ENABLE,
    OFFLINE_DISABLE,
    SERVER_CHECKIN,
    SERVER_CHECKIN_FAILED,
    DO_MIGRATION,
  ]),
  initTypes: ['@@redux-undo/INIT'],
}

// const logger = (tag, child) => (state, action) => {
//   Raven.captureBreadcrumb({
//     message: 'Redux: ' + action.type,
//     category: 'action',
//     data: action,
//   })

//   state = state || undefined
//   return child(state, action)
// }

const combinedReducers = undoable(
  combineReducers({
    entries,
    view,
    customization,
  }),
  undoOpts,
)

const limitLength = (targetLength, array) => {
  if (array.length > targetLength) {
    array.splice(0, Math.round(targetLength / 5))
  }
}

export const reduce = (state, action) => {
  if (action.type === RESET_STATE) {
    state = {
      past: [],
      present: undefined,
      future: [],
    }
  }

  const newState = combinedReducers(state, action)
  limitLength(50, newState.past)
  limitLength(50, newState.future)
  return newState
}
