// TODO: setup  polyfills

import './lib/sentry'

// stylesheets
import './assets/normalize.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/balloon.css'
import './assets/toggle-switch.css'
import './assets/fonts.css'
import './assets/pure-tables.css'
import './assets/styles.css'
import './assets/photon/css/photon.css'

// offline detection
import './lib/offline'

// react
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

// container component
import Container from './Layout'
import ErrorBoundary from './elements/ErrorBoundary'

// store
import { store } from './model/store'

// keyboard shortcuts
import shortcuts from './lib/shortcuts'

// regular server handshakes
import './lib/socket.js'

// is electron
import isElectron from 'is-electron'

import { Helmet } from 'react-helmet'

import * as serviceWorker from './serviceWorker'

// root node
const root = (
  <ErrorBoundary>
    <Provider store={store}>
      <div>
        <Helmet>
          <title>Health Age</title>
        </Helmet>
        {shortcuts}
        <Container />
      </div>
    </Provider>
  </ErrorBoundary>
)

// render root node onto specified element
ReactDOM.render(root, document.getElementById('root'))

// tell Intercom that we're ready
window.Intercom('boot', {
  app_id: 'qgr7dwey',
  alignment: isElectron() ? 'right' : 'left',
})

serviceWorker.register()
