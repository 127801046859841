import styled from 'styled-components'
import React from 'react'
import autoBind from 'auto-bind'

import Spinner from './Spinner'

const Container = styled.a`
  color: white !important;
  padding: 0px 14px;
  background: #c03041;
  font-size: 19px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.2);
  display: flex;
  height: 40px;
  align-items: center;
  margin-right: ${(p) => p.last || '15px'};
  text-decoration: none;
  box-shadow: 0px 1px 3px -1px rgb(98, 24, 33);

  &:active {
    background: hsla(353, 60%, 38%, 1);
    border-color: hsla(353, 60%, 38%, 1);
    box-shadow: 0px 1px 1px 0px rgb(98, 24, 33);
  }
`

const LoadingSpinner = styled(Spinner)`
  margin-right: 14px;
  height: 24px;
`

class Button extends React.Component {
  constructor() {
    super()
    autoBind(this)
    this.state = {
      loading: false,
    }
  }
  handleClick() {
    if (this.props.showLoading && this.props.onClick) {
      // wait for the render to happen
      this.setState({ loading: true }, () => {
        console.log('showing loading screen')
        window.setTimeout(() => {
          this.props.onClick && this.props.onClick()
          // disable the spinner after the event handler fires
          this.setState({ loading: false })
        }, 100)
      })
    } else {
      this.props.onClick && this.props.onClick()
    }
  }
  render() {
    // destructure props
    const { className, children, href } = this.props

    // show the spinner if we're loading
    let spinner = this.state.loading ? <LoadingSpinner /> : ''

    // return the button
    return (
      <Container
        last={this.props.last}
        className={className}
        onClick={this.handleClick}
        href={href}
      >
        {spinner}
        {children}
      </Container>
    )
  }
}

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(p) => p.top && '14px'};
  margin-top: ${(p) => p.bottom && '14px'};
  align-items: ${(p) => (p.bottom ? 'flex-start' : 'flex-end')};
  box-sizing: border-box;

  @media (max-width: 8.5in) {
    margin-right: 10px;
    margin-left: 10px;
  }
`
const ButtonGroup = styled.div`
  display: flex;
`

export { Button, ButtonRow, ButtonGroup }
