// external libs
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
// import { Focusable } from 'react-event-as-prop'

// local libs
import { getBmi, isValidWeight, isValidHeight } from '../lib/bmi'
import { t } from '../lib/i18n'
import { meta } from '../model/selectors'
import { updateMeta } from '../model/actions'

// elements
import GenderButtons from './elements/GenderButtons'
import Field from './elements/Field'

const Container = styled.form`
  padding: 14px 15px 7px 15px;
  display: flex;
  background: ${(p) => (p.green ? 'hsl(153, 63%, 40%)' : '#c13042')};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  color: white;
  font-weight: 500;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const Gender = styled(GenderButtons)`
  margin-bottom: 13px;
`

const BmiDisplay = styled.div`
  font-size: 33px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.52);
  flex-grow: 1;
  display: flex;
  justify-content: center;
  min-width: 8ch;
`

const bindToMetaValue = connect(
  // mapState
  (state, { slug }) => {
    const entryMeta = meta(state)

    if (Array.isArray(slug)) {
      const props = {}
      slug.forEach((s) => (props[s] = entryMeta.get(s)))
      return props
    } else {
      return {
        value: entryMeta.get(slug),
      }
    }
  },
  // eventMap
  {
    update: updateMeta,
  },
)

const BoundField = bindToMetaValue((props) => {
  const { slug, value, update, isValid } = props

  return (
    <Field
      {...props}
      value={value || ''}
      onChange={(e) => update(slug, e.target.value)}
      valid={isValid ? isValid(value) : undefined}
    />
  )
})

const BoundBmiDisplay = bindToMetaValue(({ height, weight }) => {
  const bmi = getBmi(String(height), String(weight), true)

  console.log('bmi', bmi, height, weight)

  let bmiDisplay = ''
  if (bmi.value) {
    bmiDisplay = t(`BMI:`) + ' ' + bmi.value
  }

  return <BmiDisplay>{bmiDisplay}</BmiDisplay>
})

const BoundGender = bindToMetaValue(({ slug, value, update, ...props }) => {
  return (
    <Gender
      {...props}
      gender={value}
      onChangeGender={(newValue) => update(slug, newValue)}
    />
  )
})

const Header = ({ ...events }) => {
  return (
    <Container {...events}>
      <Row>
        <BoundField large label={t(`Name`)} type="text" autoFocus slug="name" />

        <BoundGender slug="gender" style={{ marginLeft: '0.5rem' }} />
      </Row>

      <Row>
        <BoundField label={t(`Age`)} slug={'age'} />

        <BoundField
          label={t(`Weight`)}
          className="numberInput"
          isValid={isValidWeight}
          friendlyMsg={t(`Accepts kg or lbs.`)}
          invalidMsg={t(`Invalid Weight (please specify kg or lbs)`)}
          slug="weight"
        />

        <BoundField
          label={t(`Height`)}
          className="numberInput heightInput"
          isValid={isValidHeight}
          friendlyMsg={t(`Accepts cm or in.`)}
          invalidMsg={t(`Invalid Height (please specify cm, in, ', or ")`)}
          slug="height"
        />

        <BoundBmiDisplay slug={['height', 'weight']} />
      </Row>
    </Container>
  )
}

export default Header
