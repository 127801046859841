import React from 'react'
import { connect } from 'react-redux'
import isElectron from 'is-electron'

import { ButtonRow, ButtonGroup, Button } from '../elements/Button'
import ReportView from '../ReportView'
import { t } from '../lib/i18n'
import { viewSurvey, updateMeta } from '../model/actions'
import { getMailto } from '../lib/emailReport'
import { currentEntry } from '../model/selectors'
import { getSummary } from '../lib/healthAge'
import { print } from '../lib/print.js'

class ReportLayout extends React.Component {
  componentDidMount() {
    document.querySelector('.scrollingContainer').scrollTop = 0
  }
  render() {
    const { viewSurvey, summary } = this.props
    const electron = isElectron()

    return (
      <div>
        {!electron && (
          <ButtonRow top key="buttonRow">
            <Button onClick={viewSurvey}>{t(`Back`)}</Button>
            <ButtonGroup>
              <Button onClick={print} showLoading>
                {t(`Print Report`)}
              </Button>
              <Button last href={getMailto(summary)}>
                {t(`Email Report`)}
              </Button>
            </ButtonGroup>
          </ButtonRow>
        )}

        <ReportView key="reportView" results={summary} />
      </div>
    )
  }
}

const mapState = (state) => {
  return {
    summary: getSummary(currentEntry(state)),
  }
}
const eventMap = {
  viewSurvey,
  updateMeta,
}

export default connect(mapState, eventMap)(ReportLayout)
