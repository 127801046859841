import React from 'react'
import styled from 'styled-components'

import { desktopUrl } from '../lib/defines'

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  background: #edece9;
`

const Box = styled.div`
  width: 500px;
  display: flex;
  margin: auto;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  background: white;
  box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.12);
`

const Header = styled.h2`
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 6px;
`

const P = styled.p`
  font-size: 17px;
`

const Expired = () => {
  return (
    <Container>
      <Box>
        <Header>Offline Installation Expired</Header>
        <P>
          Discover Your Health Age requires an internet connection at least
          every 30 days to renew the installation. Please briefly connect your
          computer to internet to continue working.
        </P>
        <P>
          If you need long-term offline access please use our{' '}
          <a href={desktopUrl}>standalone desktop app</a>. For a technical
          explanation of why this requirement is necessary see the{' '}
          <a href="https://manual.discoverhealthage.com/">user's manual</a>.
        </P>
      </Box>
    </Container>
  )
}

export default Expired
