import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { config } from '../model/globalConfig'
import { userLevel, currentView } from '../model/selectors'
import { updateIntercom } from '../lib/util'
import { version } from '../../package.json'

const IntercomUpdater = ({ currentView, userLevel }) => {
  updateIntercom({
    language: config('locale'),
    current_view: currentView,
    user_level: userLevel,
    app_version: version,
  })
  return null
}

const mapState = (state) => {
  return {
    currentView: currentView(state),
    userLevel: userLevel(state),
  }
}

IntercomUpdater(connect(mapState, null)(IntercomUpdater))

export default withRouter(IntercomUpdater)
