import React from 'react'
import styled from 'styled-components'

import svg from '../assets/reportDivider.svg'

const Img = styled.img`
  height: ${(p) => (p.thick ? '7px' : '5px')};
  width: 100%;
  display: block;
`
const Divider = ({ thick }) => {
  return <Img thick={thick} src={svg} />
}

export default Divider
