import query from 'query-string'
import { Map } from 'immutable'

import { atou } from '../lib/utoa'

// In certain cases we need to re-calculate this dynamically
const getParams = () =>
  Map(query.parse(window.location.search))
    .map((q) => {
      return q === null ? true : q
    })
    .toJS()

const params = getParams()

const decodeParam = (param) => {
  return params[param] && atou(params[param])
}

export default params
export { decodeParam, params, getParams }
