import query, { decodeParam } from '../lib/query'

const PREFIX = 'haConfig--'

const defaults = {
  locale: 'en',
  passedWelcomeScreen: false,
  strings: '[]',
  sessionId: String(Math.random()),
}

const setConfig = (key, value) => {
  return window.localStorage.setItem(PREFIX + key, value)
}

const getConfig = (key) => {
  let value = window.localStorage.getItem(PREFIX + key)

  if (key === 'locale') {
    if (query.locale) {
      return query.locale
    }
  }

  if (key === 'customLogo') {
    if (query.logo) {
      return decodeParam('logo')
    }
  }

  if (!value) {
    value = defaults[key]
    if (value) {
      setConfig(key, value)
    }
  }

  return value
}

const config = (key, value) => {
  if (value === undefined) {
    return getConfig(key, value)
  } else {
    return setConfig(key, value)
  }
}

window.config = config

export { config }

// un-break browsers with huge logos
if ((config('customLogo') || '').length > 2000000) {
  config('customLogo', '')
}
