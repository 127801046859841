import React from 'react'
import styled from 'styled-components'
import Checkbox from './Checkbox'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  page-break-inside: avoid;
  cursor: pointer;
  -webkit-user-select: none;

  &:hover {
    background: hsla(0, 0%, 97%, 1);
  }
`

const Label = styled.div`
  flex: auto;
  min-width: 0;
  display: flex;
`

const TextInput = styled.input`
  border: none;
  border-bottom: 1px solid black;
  background: transparent;
  outline: none;
  border-radius: 0;
  flex: auto;
  min-width: 5rem;
`

const InterestCheckbox = ({ checked, label, onChange, keystroke }) => {
  const toggle = () => onChange(!checked)

  return (
    <Container onClick={toggle}>
      <Checkbox checked={checked} keystroke={keystroke} />
      <Label>{label}</Label>
    </Container>
  )
}

const OtherCheckbox = ({ value, onChange, keystroke }) => {
  const checked = !!value // cast as boolean
  const handleInput = (e) => onChange(e.target.value)

  return (
    <Container>
      <Checkbox checked={checked} />
      <Label>
        <TextInput type="text" value={value || ''} onChange={handleInput} />
      </Label>
    </Container>
  )
}

export { InterestCheckbox, OtherCheckbox }
