import React from 'react'
import ms from 'mousetrap'

let globalCodes = []

ms.prototype.stopCallback = (e, element, combo) => {
  // if the element has the class "mousetrap" then no need to stop
  if ((' ' + element.className + ' ').indexOf(' mousetrap ') > -1) {
    return false
  }

  // if the combo is in the globalCodes list, then no need to stop
  if (globalCodes.includes(combo)) {
    return false
  }

  // stop for input, select, and textarea
  return element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA' || (element.contentEditable && element.contentEditable === 'true')
}

class Shortcut extends React.Component {
  componentDidMount () {
    ms.bind(this.props.code, this.props.handler)
    if (this.props.global) {
      globalCodes.push(this.props.code)
    }
  }
  componentWillUnmount () {
    ms.unbind(this.props.code)
    globalCodes = globalCodes.filter(c => c !== this.props.code)
  }
  render () {
    return null
  }
}

const shortcut = (code, handler, gb) => {
  return <Shortcut code={code} handler={handler} key={code} global={gb} />
}

export default Shortcut
export { shortcut }
