import React, { useReducer } from 'react'
import styled from 'styled-components'
import { iStrings } from '../lib/strings.js'
import { fromJS } from 'immutable'
import yoinkit from 'yoinkit'
import base64 from 'base64-arraybuffer'
import utf8 from 'utf-8'
import languages from '../lib/languages'

const initialState = fromJS({
  locale: 'es',
  overrides: {},
})
const reducer = (state, action) => {
  switch (action.type) {
    case 'translate':
      const { english, newValue } = action
      if (newValue) {
        return state.setIn(
          ['overrides', english, state.get('locale')],
          newValue,
        )
      } else {
        return state.deleteIn(['overrides', english, state.get('locale')])
      }
    case 'changeLocale':
      return state.setIn(['locale'], action.newLocale)
    default:
      throw new Error()
  }
}

const translate = (english, newValue) => {
  return { type: 'translate', english, newValue }
}

const changeLocale = (newLocale) => {
  return { type: 'changeLocale', newLocale }
}

const Translation = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const locale = state.get('locale')
  const missingTranslations = iStrings.filter((_mappings, english) => {
    console.log(english)

    let translationForString = state
      .get('overrides')
      .getIn([english, locale], iStrings.getIn([english, locale]))

    console.log('translationForString', translationForString)

    return !translationForString
  }).size

  return (
    <Container>
      <Header
        overrides={state.get('overrides')}
        locale={state.get('locale')}
        dispatch={dispatch}
        missingTranslations={missingTranslations}
      />
      <Strings
        overrides={state.get('overrides')}
        locale={state.get('locale')}
        dispatch={dispatch}
      />
    </Container>
  )
}

const Container = styled.div`
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 60px 20px 20px 20px;
`

const DownloadButton = styled.button`
  margin-left: auto;
`

const Header = ({
  overrides,
  locale,
  dispatch,
  missingTranslations,
  hideCompleted,
  showAll,
}) => {
  const downloadTranslations = () => {
    let json = overrides.toJS()
    json = JSON.stringify(json, null, 2)
    json = base64.encode(utf8.setBytesFromString(json))
    yoinkit({
      data: json,
      encoding: 'base64',
      contentType: 'application/json',
      filename: `healthage-translation-${locale}.json`,
    })
  }

  return (
    <HeaderContainer>
      <DropdownLabel>Translate for:</DropdownLabel>
      <select
        value={locale}
        onChange={(e) => dispatch(changeLocale(e.target.value))}
      >
        {languages
          .filter(([code]) => code !== 'en')
          .map(([code, name]) => {
            return (
              <option key={code} value={code}>
                {name}
              </option>
            )
          })}
      </select>
      <DropdownLabel style={{ marginLeft: '10px' }}>
        {missingTranslations} Missing Translations
      </DropdownLabel>
      <DownloadButton onClick={downloadTranslations}>
        Download Change File
      </DownloadButton>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  min-height: 50px;
  background: gray;
  align-items: center;
  padding: 0 2rem;

  color: white;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
`

const DropdownLabel = styled.span`
  margin-right: 1rem;
  font-size: 1.2rem;
`

const Strings = ({ overrides, dispatch, locale }) => {
  // const handleStringChange = (english, newValue) => {
  //   if (newValue) {
  //     setOverrides(overrides.setIn([english, locale], newValue))
  //   } else {
  //     setOverrides(overrides.deleteIn([english, locale]))
  //   }
  // }

  const stringRows = iStrings
    .map((translations, english) => {
      let string = overrides.getIn([english, locale], translations.get(locale))

      return (
        <String
          key={english}
          english={english}
          string={string}
          dispatch={dispatch}
        />
      )
    })
    .valueSeq()
    .toArray()

  return <StringsContainer>{stringRows}</StringsContainer>
}

const StringsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
`

class String extends React.PureComponent {
  onChange = (e) => {
    this.props.dispatch(translate(this.props.english, e.target.value))
  }
  render = () => {
    let { english, string = '' } = this.props

    return (
      <StringContainer>
        <StringLabel>{english}</StringLabel>
        <StringField
          type="text"
          value={string}
          alert={!string}
          onChange={this.onChange}
        />
      </StringContainer>
    )
  }
}

const StringContainer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;

  &:nth-child(even) {
    background: #f2f2f2;
  }
`

const StringLabel = styled.span`
  margin-bottom: 0.75rem;
  font-weight: bold;
`

const StringField = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
  ${(p) => p.alert && 'border-bottom: 2px solid red;'};
`

export default Translation
